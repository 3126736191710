import Avatar from "@material-ui/core/Avatar"
// import CardBody from 'components/Card/CardBody.jsx'
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import LinearProgress from "@material-ui/core/LinearProgress"
import Snackbar from "@material-ui/core/Snackbar"
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import CloseIcon from "@material-ui/icons/Close"
import DownloadIcon from "@material-ui/icons/CloudDownload"
import EditIcon from "@material-ui/icons/Edit"
import IconOpenInNew from "@material-ui/icons/OpenInNew"
import ViewIcon from "@material-ui/icons/Pageview"
import RefreshIcon from "@material-ui/icons/Refresh"
import badgeImage from "assets/img/badges/badge-placeholder.png"
import Tag from "components/Badge/Badge.jsx"
import matchSorter from "match-sorter"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { NavLink } from "react-router-dom"
import ReactTable from "react-table"
import badgeStyles from "views/Badge/badgeStyles"
import { addBadgeAssertion, getBadge, getBadgeAssertions } from "../../api/Badges"
import BadgeContext from "../../context/badge-context"
import { isUserPrivilegedForBadge } from "helpers/Helpers"
import SearchIcon from "@material-ui/icons/Search"

const styBadgeImage = {
    textAlign: "center",
    margin: 10,
    height: 200,
    width: 200
}

const BadgeImage = props => {
    const addDefaultSrc = e => {
        e.target.src = badgeImage
    }

    return (
        <React.Fragment>
            <Avatar src={props.src} style={styBadgeImage} onError={addDefaultSrc} />
        </React.Fragment>
    )
}

const AssignDialog = ({ isOpen, onClose, onSubmit, isLoading }) => {
    const [assignees, setAssignees] = useState("")
    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Assign Badge</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To assign this badge to many users, enter all email addresses here separated with a comma.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="assignees"
                    name="assignees"
                    label="Assignee Emails"
                    value={assignees}
                    onChange={event => setAssignees(event.target.value)}
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    variant="outlined"
                    helperText="eg. johnsonbetty@wright.com, davisemily@nicholson.com, fanderson@cantu.com"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onSubmit(assignees)} color="primary">
                    Assign
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress />}
        </Dialog>
    )
}

const ApplyDialog = ({ evidenceRequired, isOpen, onClose, onSubmit, isLoading }) => {
    const [evidenceLink, setEvidenceLink] = useState("https://")
    // const [loading, setLoading] = useState(false)

    if (evidenceRequired) {
        return (
            <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Apply for Badge</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This badge requires supporting evidence before it can be assigned. Please enter evidence URL and
                        check link prior to applying. URL Must contain 'http://' or 'https://'.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="link"
                        name="link"
                        label="Link"
                        value={evidenceLink}
                        onChange={event => setEvidenceLink(event.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        helperText="eg. https://www.example-link.com/badge-details"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button onClick={() => window.open(evidenceLink, "_blank").focus()} color="primary">
                                        Check
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => onSubmit(evidenceLink)} color="primary">
                        Apply
                    </Button>
                </DialogActions>
                {isLoading && <LinearProgress />}
            </Dialog>
        )
    } else {
        return (
            <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Apply for Badge</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please click the Apply button to submit</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => onSubmit()} color="primary">
                        Apply
                    </Button>
                </DialogActions>
                {isLoading && <LinearProgress />}
            </Dialog>
        )
    }
}

const Badge = props => {
    const GC = useContext(BadgeContext)
    const badgeId = props.location.pathname.split("badge/")[1]
    const [badge, setBadge] = useState(null)
    const [badgeAssertions, setBadgeAssertions] = useState(null)
    const [loading, setLoading] = useState(false)

    const { classes } = props

    const [notificationOpen, setNotificationOpen] = useState(false)
    const [] = useState("false")
    const [notificationMessage, setNotificationMessage] = useState("")
    const handleNotificationClose = () => {
        setNotificationOpen(false)
    }
    const handleNotificationOpen = notificationMessage => {
        setNotificationOpen(true)
        setNotificationMessage(notificationMessage)
    }

    const [applyDialogOpen, setApplyDialogOpen] = useState(false)
    const handleApplyDialogOpen = () => {
        setApplyDialogOpen(true)
    }

    const handleApplyDialogClose = () => {
        setApplyDialogOpen(false)
    }

    const handleApplyDialogSubmit = evidenceLink => {
        // TODO: Handle when user already has assertion they cannot apply
        if (GC.jwtToken) {
            setLoading(true)
            const assertion = {}
            assertion["emails"] = [GC.userEmail]
            assertion["status"] = "applied"

            if (evidenceLink) assertion["evidence"] = evidenceLink

            addBadgeAssertion(GC.jwtToken, badge.pk, assertion).then(() => {
                handleApplyDialogClose()
                handleNotificationOpen("Application Submitted")
                setLoading(false)
            })
        }
    }

    const [assignDialogOpen, setAssignDialogOpen] = useState(false)
    const handleAssignDialogOpen = () => {
        setAssignDialogOpen(true)
    }
    const handleAssignDialogClose = () => {
        setAssignDialogOpen(false)
    }
    const handleAssignDialogSubmit = assignees => {
        if (GC.jwtToken) {
            setLoading(true)
            const assertion = {}
            if (assignees)
                assertion["emails"] = assignees
                    .replace(/\s/g, "")
                    .replace(/,$/, "")
                    .split(",")
            assertion["status"] = "awarded"
            assertion["reviewer"] = GC.userEmail

            addBadgeAssertion(GC.jwtToken, badge.pk, assertion).then(() => {
                getBadgeAssertions(GC.jwtToken, badgeId).then(res => {
                    setBadgeAssertions(res)
                    setLoading(false)
                    handleAssignDialogClose()
                    handleNotificationOpen("Badges Assigned")
                })
            })
        }
    }

    // Run only once
    useEffect(() => {
        if (GC.jwtToken) {
            getBadge(GC.jwtToken, badgeId).then(badge => {
                setBadge(badge)
            })
            getBadgeAssertions(GC.jwtToken, badgeId).then(res => {
                setBadgeAssertions(res)
            })
        }
    }, [GC.jwtToken])

    function fetchAssertions() {
        getBadgeAssertions(GC.jwtToken, badgeId).then(res => {
            setBadgeAssertions(res)
        })
    }

    const capitalize = s => {
        if (typeof s === "string") {
            return s.charAt(0).toUpperCase() + s.slice(1)
        } else if (typeof s === "boolean") {
            return (
                s
                    .toString()
                    .charAt(0)
                    .toUpperCase() + s.toString().slice(1)
            )
        }
        return ""
    }

    function openUrlInNewWindow(url) {
        window.open(url, "_blank").focus()
    }

    function Assertions() {
        if (badge && badgeAssertions) {
            let isUserPriv = isUserPrivilegedForBadge(GC, badge)
            if (isUserPriv) {
                return (
                    <Grid item xs={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                title="Badge Assertions"
                                action={
                                    <React.Fragment>
                                        <CSVLink
                                            data={badgeAssertions}
                                            filename={
                                                moment().format("YYYY-MM-DDTHH-mm-ss") +
                                                "-badger-export-assertions-" +
                                                badge.name
                                                    .toLowerCase()
                                                    .split(" ")
                                                    .join("-") +
                                                ".csv"
                                            }
                                        >
                                            <IconButton aria-label="Download">
                                                <DownloadIcon />
                                            </IconButton>
                                        </CSVLink>

                                        {/* FIXME: Add OnClick() Data Refresh */}
                                        <IconButton aria-label="Refresh" onClick={fetchAssertions}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            />
                            <CardContent>
                                <ReactTable
                                    data={badgeAssertions}
                                    filterable
                                    sortable
                                    defaultPageSize={20}
                                    showPageSizeOptions={false}
                                    showPageJump={false}
                                    defaultSorted={[
                                        {
                                            // the sorting model for the table
                                            id: "created",
                                            desc: true
                                        }
                                    ]}
                                    className="-striped -highlight"
                                    columns={[
                                        {
                                            Header: "User",
                                            accessor: "email",
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, { keys: ["email"] }),
                                            filterAll: true,
                                            Filter: ({ filter, onChange }) => {
                                                return (
                                                    <TextField
                                                        id="searching email"
                                                        onChange={event => onChange(event.target.value)}
                                                        value={filter ? filter.value : ""}
                                                        placeholder="Search by user..."
                                                        fullWidth
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon color="disabled" />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                )
                                            }
                                        },
                                        {
                                            Header: "Created",
                                            accessor: "created",
                                            maxWidth: 100,
                                            sortable: true,
                                            filterable: false,
                                            Cell: row => new Date(row.original.created).toLocaleDateString()
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            sortable: true,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["status"]
                                                }),
                                            filterAll: true,
                                            Filter: ({ filter, onChange }) => {
                                                return (
                                                    <TextField
                                                        id="searching email"
                                                        onChange={event => onChange(event.target.value)}
                                                        value={filter ? filter.value : ""}
                                                        placeholder="Search by status..."
                                                        fullWidth
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon color="disabled" />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                )
                                            },
                                            Cell: row => {
                                                if ("status" in row.original) {
                                                    if (row.original.status.toLowerCase() == "awarded") {
                                                        return <Tag color="success">{row.original.status}</Tag>
                                                    } else if (row.original.status.toLowerCase() == "rework") {
                                                        return <Tag color="danger">{row.original.status}</Tag>
                                                    } else {
                                                        return <Tag color="warning">{row.original.status}</Tag>
                                                    }
                                                } else {
                                                    return null
                                                }
                                            }
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "actions",
                                            minWidth: 40,
                                            sortable: false,
                                            filterable: false,
                                            // minWidth: 40,
                                            Cell: row => (
                                                <Grid container justify="center" alignItems="center">
                                                    <Grid item>
                                                        <NavLink
                                                            to={{
                                                                pathname:
                                                                    "/admin/assertion/" + row.original.pk.split(":")[1]
                                                            }}
                                                        >
                                                            <IconButton
                                                                color="default"
                                                                className={classes.button}
                                                                size="small"
                                                                edge="end"
                                                            >
                                                                <ViewIcon />
                                                            </IconButton>
                                                        </NavLink>
                                                    </Grid>
                                                    <Grid item>
                                                        <NavLink
                                                            to={{
                                                                pathname:
                                                                    "/admin/edit-assertion/" +
                                                                    row.original.pk.split(":")[1]
                                                            }}
                                                        >
                                                            <IconButton
                                                                color="default"
                                                                className={classes.button}
                                                                size="small"
                                                                edge="end"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </NavLink>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    ]}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const NotificationPanel = () => {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={notificationOpen}
                autoHideDuration={6000}
                onClose={handleNotificationClose}
                ContentProps={{
                    "aria-describedby": "message-id",
                    style: {
                        backgroundColor: "#4caf50"
                    }
                }}
                message={<span>{notificationMessage}</span>}
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleNotificationClose}>
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        )
    }

    function GetDisplay() {
        if (badge && badgeAssertions) {
            let isUserPriv = isUserPrivilegedForBadge(GC, badge)
            if (badge.reviewers == null) badge.reviewers = []
            if (badge.owners == null) badge.owners = []

            const userHasAssertion = badgeAssertions.map(assertion => assertion.email).includes(GC.userEmail)

            return (
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="Badge"
                            action={
                                <React.Fragment>
                                    {GC.userRole === "Admin" ? (
                                        <NavLink
                                            to={{
                                                pathname: "/admin/edit-badge/" + badgeId
                                            }}
                                        >
                                            <IconButton
                                                color="default"
                                                className={classes.button}
                                                size="small"
                                                edge="end"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </NavLink>
                                    ) : null}
                                </React.Fragment>
                            }
                        />

                        <CardContent>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <BadgeImage src={`${GC.badgeBucketOriginal}/${badge.image}`} />
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {/*  */}
                                {badge.requestable && !userHasAssertion ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleApplyDialogOpen}
                                    >
                                        Request Badge
                                    </Button>
                                ) : null}

                                {isUserPriv ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAssignDialogOpen}
                                    >
                                        Assign Badge
                                    </Button>
                                ) : null}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={badge.name}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="description"
                                    name="description"
                                    label="Description"
                                    value={badge.description}
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            {badge.criteria ? (
                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="criteria"
                                        name="criteria"
                                        label="Criteria"
                                        value={badge.criteria}
                                        multiline
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            ) : null}

                            {/* TODO: Check if value exists and only then disply */}
                            {/* TODO:: Change date format */}
                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="created"
                                    name="created"
                                    label="Created"
                                    value={new Date(badge.created).toLocaleDateString()}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                            {/* TODO:: Change date format */}
                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="modified"
                                    name="modified"
                                    label="Modified"
                                    value={new Date(badge.lastUpdated).toLocaleDateString()}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            {badge.link ? (
                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="link"
                                        name="link"
                                        label="Link"
                                        value={badge.link}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="openInNewWindow"
                                                        onClick={() => openUrlInNewWindow(badge.link)}
                                                    >
                                                        <IconOpenInNew />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            ) : null}

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="type"
                                    name="type"
                                    label="Type"
                                    value={capitalize(badge.type)}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="evidenceRequired"
                                    name="evidenceRequired"
                                    label="Evidence Required"
                                    value={capitalize(badge.evidenceRequired)}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="requestable "
                                    name="requestable "
                                    label="User Requestable"
                                    value={capitalize(badge.requestable)}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="owners"
                                    name="owners"
                                    label="Owners"
                                    value={badge.owners.join(",\n")}
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            {/* Check if badge.reviewers exists */}
                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="reviewers"
                                    name="reviewers"
                                    label="Reviewers"
                                    value={badge.reviewers.join(",\n")}
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )
        } else {
            return null
        }
    }

    // ################################################################################
    // Main Return
    // ################################################################################

    return (
        <Grid container>
            {GetDisplay()}
            <Assertions />
            <NotificationPanel />

            <AssignDialog
                isLoading={loading}
                isOpen={assignDialogOpen}
                onClose={handleAssignDialogClose}
                onSubmit={handleAssignDialogSubmit}
            />
            {badge ? (
                <ApplyDialog
                    isLoading={loading}
                    isOpen={applyDialogOpen}
                    onClose={handleApplyDialogClose}
                    onSubmit={handleApplyDialogSubmit}
                    evidenceRequired={badge.evidenceRequired}
                />
            ) : null}
        </Grid>
    )
}
export default withStyles(badgeStyles)(Badge)
