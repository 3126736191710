// TODO: Need to add image editing
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddAlert from "@material-ui/icons/AddAlert"
import DeleteIcon from "@material-ui/icons/Delete"
import Select from "@material-ui/core/Select"
import IconOpenInNew from "@material-ui/icons/OpenInNew"
import SaveIcon from "@material-ui/icons/Save"
// import Card from "components/Card/Card.jsx";
import Avatar from "@material-ui/core/Avatar"
import FormControl from "@material-ui/core/FormControl"
import CardHeader from "@material-ui/core/CardHeader"
import Snackbar from "components/Snackbar/Snackbar.jsx"
import React, { useContext, useEffect, useState } from "react"
import editAssertionStyles from "views/EditAssertion/EditAssertionStyles"
import InputLabel from "@material-ui/core/InputLabel"
import badgeImage from "assets/img/badges/badge-placeholder.png"
import { getBadge } from "../../api/Badges"
import { deleteAssertion, getAssertion, updateAssertion } from "../../api/Assertions"
import BadgeContext from "../../context/badge-context"
import LinearProgress from "@material-ui/core/LinearProgress"

const ReworkDialog = ({ isOpen, onClose, onSubmit, isLoading }) => {
    const [comment, setComment] = useState("")
    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Rework Required</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are about to inform the requestee that they still require to do more work. Please leave
                    justification as a comment.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="comment"
                    name="comment"
                    label="Comments"
                    value={comment}
                    onChange={event => setComment(event.target.value)}
                    fullWidth
                    multiline
                    rows="2"
                    margin="normal"
                    variant="outlined"
                    helperText="eg. Invalid link, please update"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onSubmit(comment.trim())} color="primary">
                    Send Rework Request
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress />}
        </Dialog>
    )
}

const AwardDialog = ({ isOpen, onClose, onSubmit, isLoading }) => {
    const [comment, setComment] = useState("")
    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Award Badge</DialogTitle>
            <DialogContent>
                <DialogContentText>You are about to award this badge. Please leave a comment</DialogContentText>
                <TextField
                    autoFocus
                    id="comment"
                    name="comment"
                    label="Comments"
                    value={comment}
                    onChange={event => setComment(event.target.value)}
                    fullWidth
                    multiline
                    rows="2"
                    margin="normal"
                    variant="outlined"
                    helperText="eg. Everything looks good, well done!"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onSubmit(comment.trim())} color="primary">
                    Award Badge
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress />}
        </Dialog>
    )
}

const styBadgeImage = {
    textAlign: "center",
    margin: 10,
    height: 200,
    width: 200
}

const BadgeImage = props => {
    const addDefaultSrc = e => {
        e.target.src = badgeImage
    }

    function handleImageChange() {
        // TODO: This still needs to be implemented
        console.log("handleImageChange")
    }

    return (
        <React.Fragment>
            <Avatar src={props.src} style={styBadgeImage} onError={addDefaultSrc} />
        </React.Fragment>
    )
}

const capitalize = s => {
    if (typeof s === "string") {
        return s.charAt(0).toUpperCase() + s.slice(1)
    } else if (typeof s === "boolean") {
        return (
            s
                .toString()
                .charAt(0)
                .toUpperCase() + s.toString().slice(1)
        )
    }
    return s
}

const EditAssertion = props => {
    const GC = useContext(BadgeContext)
    const assertionId = props.location.pathname.split("assertion/")[1]
    const [assertion, setAssertion] = useState(null)
    const [badge, setBadge] = useState("")
    const [assertionBadge, setAssertionBadge] = useState("")
    const [assertionEmail, setAssertionEmail] = useState("")
    const [assertionStatus, setAssertionStatus] = useState("")
    const [assertionEvidence, setAssertionEvidence] = useState("")
    const [assertionReviewer, setAssertionReviewer] = useState("")
    const [assertionComments, setAssertionComments] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteDisabled, setDeleteDisabled] = useState(true)

    const [notification, setNotification] = useState({
        open: false,
        message: "",
        color: "info"
    })
    const { classes } = props

    const [reworkDialogOpen, setReworkDialogOpen] = useState(false)
    const handleReworkDialogOpen = () => {
        setReworkDialogOpen(true)
    }

    const handleReworkDialogClose = () => {
        setReworkDialogOpen(false)
    }
    const [awardDialogOpen, setAwardDialogOpen] = useState(false)
    const handleAwardDialogOpen = () => {
        setAwardDialogOpen(true)
    }

    const handleAwardDialogClose = () => {
        setAwardDialogOpen(false)
    }

    const handleAwardDialogSubmit = comment => {
        // TODO: Handle when user already has assertion they cannot apply
        if (GC.jwtToken) {
            setLoading(true)
            var comment = {
                comment: comment,
                date: new Date().toISOString().split(".")[0] + "Z",
                email: GC.userEmail
            }

            const updatedAssertion = {}
            updatedAssertion["status"] = "awarded"
            updatedAssertion["comments"] = assertionComments.concat(comment)
            updatedAssertion["reviewer"] = GC.userEmail

            updateAssertion(GC.jwtToken, assertionId, updatedAssertion)
                .then(res => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Assertion Updated"
                    })
                    updateBadgeAttributes(res)
                    handleAwardDialogClose()
                    setLoading(false)
                })
                .catch(err => {
                    console.error("ERROR: ", err)
                    var errorMessage = err.response.data.errorMessage
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: errorMessage.message + ": " + errorMessage.payload
                    })
                })
        }
    }

    const handleReworkDialogSubmit = comment => {
        // TODO: Handle when user already has assertion they cannot apply
        if (GC.jwtToken) {
            setLoading(true)
            var comment = {
                comment: comment,
                date: new Date().toISOString().split(".")[0] + "Z",
                email: GC.userEmail
            }

            const updatedAssertion = {}
            updatedAssertion["status"] = "rework"
            updatedAssertion["comments"] = assertionComments.concat(comment)
            updatedAssertion["reviewer"] = GC.userEmail

            updateAssertion(GC.jwtToken, assertionId, updatedAssertion)
                .then(res => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Assertion Updated"
                    })
                    updateBadgeAttributes(res)
                    handleReworkDialogClose()
                    setLoading(false)
                })
                .catch(err => {
                    console.error("ERROR: ", err)
                    var errorMessage = err.response.data.errorMessage
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: errorMessage.message + ": " + errorMessage.payload
                    })
                })
        }
    }

    const handleRework = () => {
        setAssertionStatus("rework")
        var updatedAssertion = {}
        updatedAssertion.status = "rework"
        updatedAssertion.evidence = assertionEvidence
        updatedAssertion.comments = assertionComments
        updatedAssertion.reviewer = GC.userEmail

        // TODO: Add validation here

        if (GC.jwtToken) {
            updateAssertion(GC.jwtToken, assertionId, updatedAssertion)
                .then(assertion => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Badge Application Submitted"
                    })
                    updateBadgeAttributes(assertion)
                })
                .catch(err => {
                    console.error("ERROR: ", err)
                    var errorMessage = err.response.data.errorMessage
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: errorMessage.message + ": " + errorMessage.payload
                    })
                })
        }
    }

    // Run only once
    useEffect(() => {
        if (GC.jwtToken) {
            getAssertion(GC.jwtToken, assertionId).then(assertion => {
                setAssertion(assertion)
                updateBadgeAttributes(assertion)
                getBadge(GC.jwtToken, assertion.d2).then(badge => {
                    setBadge(badge)
                })
            })
        }
    }, [GC.jwtToken])

    const updateBadgeAttributes = assertion => {
        if (assertion.badge != null) setAssertionBadge(assertion.badge)
        if (assertion.email != null) setAssertionEmail(assertion.email)
        if (assertion.status != null) setAssertionStatus(assertion.status)
        if (assertion.evidence != null) setAssertionEvidence(assertion.evidence)
        if (assertion.reviewer != null) setAssertionReviewer(assertion.reviewer)
        if (assertion.comments != null) setAssertionComments(assertion.comments)
    }

    // ################################################################################
    // Functions
    // ################################################################################
    const handleNotification = notification => {
        if (!notification.open) {
            setNotification({
                open: true,
                message: notification.message,
                icon: notification.icon,
                color: notification.color
            })
            return new Promise(function() {
                setTimeout(
                    function() {
                        setNotification({
                            open: false,
                            color: notification.color
                        })
                    }.bind(this),
                    5000
                )
            })
        }
    }

    const handleResubmit = () => {
        setAssertionStatus("applied")
        var updatedAssertion = {}
        updatedAssertion.status = "applied"
        updatedAssertion.evidence = assertionEvidence
        if (GC.userRole === "Admin") updatedAssertion.reviewer = GC.userEmail

        // TODO: Add validation here

        if (GC.jwtToken) {
            updateAssertion(GC.jwtToken, assertionId, updatedAssertion)
                .then(assertion => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Badge Application Submitted"
                    })
                    updateBadgeAttributes(assertion)
                })
                .catch(err => {
                    console.error("ERROR: ", err)
                    var errorMessage = err.response.data.errorMessage
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: errorMessage.message + ": " + errorMessage.payload
                    })
                })
        }
    }

    const handleSubmit = () => {
        var updatedAssertion = {}
        updatedAssertion.status = assertionStatus
        updatedAssertion.evidence = assertionEvidence
        if (GC.userRole === "Admin") updatedAssertion.reviewer = GC.userEmail

        // TODO: Add validation here

        if (GC.jwtToken) {
            updateAssertion(GC.jwtToken, assertionId, updatedAssertion)
                .then(res => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Successfully Updated Assertion"
                    })
                    updateBadgeAttributes(res)
                })
                .catch(err => {
                    console.error("ERROR: ", err)
                    var errorMessage = err.response.data.errorMessage
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: errorMessage.message + ": " + errorMessage.payload
                    })
                })
        }
    }

    const handleDeleteAssertion = () => {
        console.log("DeleteAssertion")
        if (GC.jwtToken) {
            deleteAssertion(GC.jwtToken, assertionId)
                .then(() => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Successfully Deleted Assertion"
                    }).then(props.history.push("/admin/assertions"))
                })

                .catch(err => {
                    console.error(err.response)
                    var errorMessage = err.response.data.errorMessage
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: errorMessage.message + ": " + errorMessage.payload
                    })
                })
        }
    }

    // ################################################################################
    // Main Rending
    // ################################################################################

    const notificationPanels = (
        <Snackbar
            place="tr"
            color={notification.color}
            icon={AddAlert}
            message={notification.message}
            open={notification.open}
            closeNotification={() => setNotification({ open: false })}
            close
        />
    )

    function openUrlInNewWindow(url) {
        window.open(url, "_blank").focus()
    }

    function DangerPanel() {
        return (
            <Grid container>
                <Grid item xs={6}>
                    <Card className={[classes.dangerPanel, classes.card]}>
                        <CardContent>
                            <Typography className={classes.title} component="h5" variant="h5">
                                Danger Zone
                            </Typography>
                            <Typography className={classes.title} component="h7" variant="h7">
                                To enable a dangerous feature use the checkbox
                            </Typography>

                            <List dense>
                                <ListItem key="delete">
                                    <ListItemIcon>
                                        <Checkbox
                                            color="default"
                                            edge="start"
                                            checked={!deleteDisabled}
                                            onChange={() => setDeleteDisabled(!deleteDisabled)}
                                            value={deleteDisabled}
                                        />
                                    </ListItemIcon>

                                    <ListItemText
                                        primary="Delete this assertion"
                                        secondary="Once you delete an assertion, there is no going back. Please be certain."
                                    />

                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        edge="end"
                                        // color="secondary"
                                        disabled={deleteDisabled}
                                        onClick={() => handleDeleteAssertion()}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>

                                <Divider variant="middle" />

                                {/* Add other dangerous features here... */}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    function GetDisplay() {
        if (assertion && badge) {
            return (
                <Grid container>
                    <Grid item xs={6}>
                        <Card className={classes.card}>
                            <CardHeader title="Edit Assertion" />
                            <CardContent>
                                <Grid container justify="center" alignItems="center">
                                    <BadgeImage src={`${GC.badgeBucketOriginal}/${badge.image}`} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="badge"
                                        name="badge"
                                        label="Badge"
                                        value={assertionBadge}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="recipient"
                                        name="recipient"
                                        label="Recipient"
                                        value={assertionEmail}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="awarded"
                                        name="awarded"
                                        label="Awarded"
                                        value={new Date(assertion.created).toLocaleDateString()}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="status"
                                        name="status"
                                        label="Status"
                                        value={capitalize(assertionStatus)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="evidence"
                                        name="evidence"
                                        label="Evidence"
                                        value={assertionEvidence}
                                        onChange={event => setAssertionEvidence(event.target.value)}
                                        helperText="eg. https://www.example-link.com/badge-details"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: GC.userRole === "User" ? false : true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="openInNewWindow"
                                                        onClick={() => openUrlInNewWindow(assertionEvidence)}
                                                    >
                                                        <IconOpenInNew />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                {assertionComments && assertionComments.length > 0 ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="comments"
                                            name="comments"
                                            label="Comments"
                                            value={assertionComments
                                                .map(comment => {
                                                    return `${comment["email"]}: ${comment["comment"]}\n`
                                                })
                                                .join("")}
                                            fullWidth
                                            multiline
                                            margin="normal"
                                            variant="outlined"
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                                {assertionReviewer ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            id="reviewer"
                                            name="reviewer"
                                            label="Reviewer"
                                            value={assertionReviewer}
                                            onChange={event => setAssertionReviewer(event.target.value)}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                                {GC.userEmail === assertion.email && assertionStatus === "rework" ? (
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item>
                                            {/*Open Dialog and ask for cooment and link update if needed  */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.submitButton}
                                                onClick={e => handleResubmit(e)}
                                            >
                                                <SaveIcon />
                                                Resubmit Request
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : null}

                                {(badge.owners.includes(GC.userEmail) || badge.reviewers.includes(GC.userEmail)) &&
                                assertionStatus !== "awarded" ? (
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleReworkDialogOpen}
                                            >
                                                <SaveIcon />
                                                Requires Rework
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleAwardDialogOpen}
                                            >
                                                <SaveIcon />
                                                Award Badge
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }
    // ################################################################################
    // Main Return
    // ################################################################################

    return (
        <Grid container>
            {GetDisplay()}

            {notificationPanels}
            <DangerPanel />

            <ReworkDialog
                isLoading={loading}
                isOpen={reworkDialogOpen}
                onClose={handleReworkDialogClose}
                onSubmit={handleReworkDialogSubmit}
            />
            <AwardDialog
                isLoading={loading}
                isOpen={awardDialogOpen}
                onClose={handleAwardDialogClose}
                onSubmit={handleAwardDialogSubmit}
            />
        </Grid>
    )
}

export default withStyles(editAssertionStyles)(EditAssertion)
