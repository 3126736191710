function isUserAdmin(userRole) {
    let result = false
    result = userRole === "Admin"

    return result
}

function isUserBadgeOwner(email, badge) {
    let result = false
    if ("owners" in badge) {
        result = badge.owners.includes(email)
    }

    return result
}

function isUserBadgeReviewer(email, badge) {
    // * Check if user is a badge reviewer
    let result = false
    if ("reviewers" in badge) {
        result = badge.reviewers.includes(email)
    }

    return result
}

export function isUserPrivilegedForBadge(globalContext, badge) {
    let result = false
    let isAdmin = isUserAdmin(globalContext.userRole)
    let isOwner = isUserBadgeOwner(globalContext.userEmail, badge)
    let isReviewer = isUserBadgeReviewer(globalContext.userEmail, badge)
    result = isAdmin || isOwner || isReviewer

    return result
}
