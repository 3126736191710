import React, { useState, useEffect, useContext } from "react"
import BadgeContext from "../context/badge-context"
import { Redirect } from "react-router-dom"
import cx from "classnames"
import PropTypes from "prop-types"
import { Switch, Route } from "react-router-dom"
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx"
import Footer from "components/Footer/Footer.jsx"
import Sidebar from "components/Sidebar/Sidebar.jsx"
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import routes from "routes.js"
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx"
import logo from "assets/img/dxc-logo.svg"

var ps

const Dashboard = props => {
    const globalState = useContext(BadgeContext)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [color] = useState("orange")
    const [bgColor] = useState("black")
    const [fixedClasses, setFixedClasses] = useState("dropdown")
    const { classes, ...rest } = props
    // const classes = useStyles()
    const mainPanelClasses =
        classes.mainPanel +
        " " +
        cx({
            // [classes.mainPanelSidebarMini]: miniActive,
            [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
        })
    // ref for main panel div
    const mainPanel = React.createRef()
    // Run only once
    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            })
            document.body.style.overflow = "hidden"
        }

        window.addEventListener("resize", resizeFunction)

        return () => {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy()
            }
            window.removeEventListener("resize", resizeFunction)
        }
    })

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const getRoute = () => {
        return props.location.pathname !== "/admin/full-screen-maps"
    }

    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text"
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views)
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].name
                }
            }
        }
        return <Redirect to="/admin/about" />
    }

    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views)
            }
            if (prop.layout === "/admin") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />
            } else {
                return null
            }
        })
    }

    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false)
        }
    }

    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logoText={globalState.userEmail}
                logo={logo}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                bgColor={bgColor}
                userRole={globalState.userRole}
                {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
                <AdminNavbar brandText={getActiveRoute(routes)} handleDrawerToggle={handleDrawerToggle} {...rest} />

                {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <Switch>{getRoutes(routes)}</Switch>
                        </div>
                    </div>
                ) : (
                    <div className={classes.map}>
                        <Switch>{getRoutes(routes)}</Switch>
                    </div>
                )}
                <Footer fluid />
            </div>
        </div>
    )
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(appStyle)(Dashboard)
