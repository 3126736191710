import Avatar from "@material-ui/core/Avatar"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import AddAlert from "@material-ui/icons/AddAlert"
import IconOpenInNew from "@material-ui/icons/OpenInNew"
import badgeImage from "assets/img/badges/badge-placeholder.png"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import GridContainer from "components/Grid/GridContainer.jsx"
import Snackbar from "components/Snackbar/Snackbar.jsx"
import React, { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import AssertionStyles from "views/Assertion/AssertionStyles"
import { getAssertion } from "../../api/Assertions"
import BadgeContext from "../../context/badge-context"
import { getBadge } from "../../api/Badges"
import { getUser } from "../../api/Users"
import EditIcon from "@material-ui/icons/Edit"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

// ################################################################################
// Main Class
// ################################################################################

const styBadgeImage = {
    textAlign: "center",
    margin: 10,
    height: 200,
    width: 200
}

const BadgeImage = props => {
    const addDefaultSrc = e => {
        e.target.src = badgeImage
    }

    return (
        <React.Fragment>
            <Avatar src={props.src} style={styBadgeImage} onError={addDefaultSrc} />
        </React.Fragment>
    )
}

const Assertion = props => {
    const GC = useContext(BadgeContext)
    const { classes } = props
    const assertionId = props.location.pathname.split("assertion/")[1]
    const [assertion, setAssertion] = useState("")
    const [badge, setBadge] = useState("")
    const [user, setUser] = useState("")

    const [notification, setNotification] = useState({
        open: false,
        message: "",
        color: "success"
    })

    useEffect(() => {
        if (GC.jwtToken) {
            getAssertion(GC.jwtToken, assertionId).then(assertion => {
                setAssertion(assertion)

                getUser(GC.jwtToken, assertion.d1).then(user => {
                    setUser(user)
                })

                getBadge(GC.jwtToken, assertion.d2).then(badge => {
                    setBadge(badge)
                })
            })
        }
    }, [GC.jwtToken])

    function openUrlInNewWindow(url) {
        window.open(url, "_blank").focus()
    }

    const capitalize = s => {
        if (typeof s === "string") {
            return s.charAt(0).toUpperCase() + s.slice(1)
        } else if (typeof s === "boolean") {
            return (
                s
                    .toString()
                    .charAt(0)
                    .toUpperCase() + s.toString().slice(1)
            )
        }
        return ""
    }

    function NotificationPanels() {
        return (
            <Snackbar
                place="tr"
                color={notification.color}
                icon={AddAlert}
                message={notification.message}
                open={notification.open}
                closeNotification={() => this.setState({ tr: false })}
                close
            />
        )
    }

    function AssertionDisplay() {
        if (assertion) {
            return (
                <Grid container>
                    <Grid item xs={6}>
                        <Card className={classes.card}>
                            <CardHeader
                                title="Assertion"
                                action={
                                    <React.Fragment>
                                        <a
                                            href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME"
                                            target="_blank"
                                        >
                                            <IconButton
                                                color="default"
                                                className={classes.button}
                                                size="small"
                                                edge="end"
                                            >
                                                <LinkedInIcon />
                                            </IconButton>
                                        </a>
                                        <a download href={`${GC.badgeBucketBaked}/${assertionId}.png`}>
                                            <IconButton
                                                color="default"
                                                className={classes.button}
                                                size="small"
                                                edge="end"
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </a>
                                        <NavLink
                                            to={{
                                                pathname: "/admin/edit-assertion/" + assertionId
                                            }}
                                        >
                                            <IconButton
                                                color="default"
                                                className={classes.button}
                                                size="small"
                                                edge="end"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </NavLink>
                                    </React.Fragment>
                                }
                            />

                            <CardContent>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <BadgeImage src={`${GC.badgeBucketOriginal}/${badge.image}`} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="link"
                                        name="link"
                                        label="Public Link"
                                        value={`${GC.apiOpenBadge}/assertions/${assertionId}?email=${user.email}`}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="openInNewWindow"
                                                        onClick={() =>
                                                            openUrlInNewWindow(
                                                                `${GC.apiOpenBadge}/assertions/${assertionId}?email=${
                                                                    user.email
                                                                }`
                                                            )
                                                        }
                                                    >
                                                        <IconOpenInNew />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="badge"
                                        name="badge"
                                        label="Badge"
                                        value={badge.name}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="recipient"
                                        name="recipient"
                                        label="Recipient"
                                        value={user.email}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="awarded"
                                        name="awarded"
                                        label="Awarded"
                                        value={new Date(assertion.created).toLocaleDateString()}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="lastUpdated"
                                        name="lastUpdated"
                                        label="Last Updated"
                                        value={new Date(assertion.lastUpdated).toLocaleDateString()}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                {/* TODO: Check if value exists and only then disply */}
                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        id="status"
                                        name="status"
                                        label="Status"
                                        value={capitalize(assertion.status)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="evidence"
                                        name="evidence"
                                        label="Evidence"
                                        value={assertion.evidence}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="openInNewWindow"
                                                        onClick={() => openUrlInNewWindow(assertion.evidence)}
                                                    >
                                                        <IconOpenInNew />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                {assertion.reviewer ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            id="reviewer"
                                            name="reviewer"
                                            label="Reviewer"
                                            value={assertion.reviewer}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                ) : null}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )
        } else {
            return null
        }
    }

    // ################################################################################
    // Main Return
    // ################################################################################

    return (
        <GridContainer>
            <AssertionDisplay />
            <NotificationPanels />
        </GridContainer>
    )
}

// ################################################################################
// Export
// ################################################################################
export default withStyles(AssertionStyles)(Assertion)
