import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
// import UserApi from "../../Api/users";
// react component for creating dynamic tables
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import Grid from "@material-ui/core/Grid"

import GridItem from "components/Grid/GridItem.jsx"

import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import RefreshIcon from "@material-ui/icons/Refresh"
import IconButton from "@material-ui/core/IconButton"

import ReactTable from "react-table"
import { NavLink } from "react-router-dom"
import matchSorter from "match-sorter"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AssertionsStyles from "views/Assertions/AssertionsStyles"

import BadgeContext from "../../context/badge-context"
import { getAssertions } from "api/Assertions"
import Tag from "components/Badge/Badge.jsx"

import moment from "moment"
import DownloadIcon from "@material-ui/icons/CloudDownload"
import { CSVLink } from "react-csv"

import ViewIcon from "@material-ui/icons/Pageview"
import EditIcon from "@material-ui/icons/Edit"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
const Assertions = props => {
    const globalContext = useContext(BadgeContext)
    // const [assertions] = useState(props.assertions);
    const { classes } = props
    // Run only once

    useEffect(() => {
        fetchAssertions()
    }, [])

    function fetchAssertions() {
        // BUG
        //When assertions are empty,
        if (globalContext.jwtToken) {
            getAssertions(globalContext.jwtToken).then(res => {
                globalContext.setAssertions(res)
            })
        }
    }

    const columns = [
        {
            Header: "User",
            accessor: "email",
            //sortable: false,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => {
                return (
                    <TextField
                        id="searching email"
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        placeholder="Search by user..."
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            }
        },
        {
            Header: "Badge",
            accessor: "badge",
            sortable: false,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["badge"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => {
                return (
                    <TextField
                        id="search-name"
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        placeholder="Search by badge..."
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            }
        },
        {
            Header: "Issued On",
            accessor: "created",
            sortable: true,
            filterable: false,
            Cell: row => {
                return new Date(row.original.created).toLocaleDateString()
            }
        },
        {
            Header: "Status",
            accessor: "status",
            // maxWidth: 100,
            sortable: true,
            filterAll: true,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["status"] }),
            Filter: ({ filter, onChange }) => {
                return (
                    <TextField
                        id="search-status"
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        placeholder="Search by status..."
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            },
            Cell: row => {
                if ("status" in row.original) {
                    if (row.original.status.toLowerCase() == "awarded") {
                        return <Tag color="success">{row.original.status}</Tag>
                    } else if (row.original.status.toLowerCase() == "rework") {
                        return <Tag color="danger">{row.original.status}</Tag>
                    } else {
                        return <Tag color="warning">{row.original.status}</Tag>
                    }
                } else {
                    return null
                }
            }
        },
        {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            // minWidth: 40,
            Cell: row => (
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <NavLink
                            to={{
                                pathname: "/admin/assertion/" + row.original.pk.split(":")[1]
                            }}
                        >
                            <IconButton
                                color="default"
                                className={classes.button}
                                size="small"
                                edge="end"
                                aria-label="add an alarm"
                            >
                                <ViewIcon />
                            </IconButton>
                        </NavLink>
                    </Grid>
                    {/* Only show if admin */}
                    {globalContext.userRole === "Admin" ? (
                        <Grid item>
                            <NavLink
                                to={{
                                    pathname: "/admin/edit-assertion/" + row.original.pk.split(":")[1]
                                }}
                            >
                                <IconButton
                                    color="default"
                                    className={classes.button}
                                    aria-label="add an alarm"
                                    size="small"
                                    edge="end"
                                >
                                    <EditIcon />
                                </IconButton>
                            </NavLink>
                        </Grid>
                    ) : null}
                </Grid>
            )
        }
    ]

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader
                        action={
                            <React.Fragment>
                                <CSVLink
                                    data={globalContext.assertions}
                                    filename={moment().format("YYYY-MM-DDTHH-mm-ss") + "-badger-export-assertions.csv"}
                                >
                                    <IconButton aria-label="Download">
                                        <DownloadIcon />
                                    </IconButton>
                                </CSVLink>

                                <IconButton aria-label="Refresh" onClick={fetchAssertions}>
                                    <RefreshIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    >
                        {/* <h4>Assertions</h4> */}
                    </CardHeader>
                    <CardContent>
                        <ReactTable
                            data={globalContext.assertions}
                            filterable
                            sortable
                            defaultSorted={[
                                {
                                    // the sorting model for the table
                                    id: "created",
                                    desc: true
                                }
                            ]}
                            columns={columns}
                            defaultPageSize={20}
                            className="-striped -highlight"
                        />
                    </CardContent>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

Assertions.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(AssertionsStyles)(Assertions)
