import { badgerApi } from "./ApiConfig";
//############################################################
// API starts here
//############################################################

export function getBadges(authorization) {
  return badgerApi
    .get("/badges", {
      headers: {
        authorization: authorization
      }
    })
    .then(res => res.data)
    .catch(err => console.error(err));
}

export function getBadge(authorization, id) {
  return badgerApi
    .get("/badges/" + id, {
      headers: {
        authorization: authorization
      }
    })
    .then(res => res.data)
    .catch(err => console.error(err));
}

export function getBadgeMetadata(authorization) {
  return badgerApi
    .get("/badges/meta", {
      headers: {
        authorization: authorization
      }
    })
    .then(res => res.data)
    .catch(err => console.error(err));
}

export function getBadgeAssertions(authorization, id) {
  return badgerApi
    .get(`/badges/${id}/assertions`, {
      headers: {
        authorization: authorization
      }
    })
    .then(res => res.data)
    .catch(err => console.error(err));
}

export function addBadgeAssertion(authorization, id, payload) {
  return badgerApi
    .post(`/badges/${id}/assertions`, payload, {
      headers: {
        authorization: authorization
      }
    })
    .then(res => res.data)
    .catch(err => console.error(err));
}

export function addBadge(authorization, payload) {
  return badgerApi.post("/badges", payload, {
    headers: {
      authorization: authorization
    }
  });
}

export function updateBadge(authorization, id, payload) {
  return badgerApi.patch(`/badges/${id}`, payload, {
    headers: {
      authorization: authorization
    }
  });
}

export function deleteBadge(authorization, id) {
  return badgerApi.delete(`/badges/${id}`, {
    headers: {
      authorization: authorization
    }
  });
}
