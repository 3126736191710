import {
	cardTitle,
	grayColor
} from 'assets/jss/material-dashboard-pro-react.jsx'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.jsx'

const badgeStyles = theme => ({
	...cardTitle,
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		'& small': {
			fontSize: '80%',
			fontWeight: '400'
		}
	},
	card: {
		margin: '15px'
	},
	pre: {
		// overflowX: "auto",
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word'
	},
	badgeImage: {
		textAlign: 'center',
		margin: 10,
		height: 200,
		width: 200
	},
	labelHorizontal: {
		// color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: '14px',
		lineHeight: '1.428571429',
		fontWeight: '400',
		paddingTop: '39px',
		marginRight: '0',
		'@media (min-width: 992px)': {
			float: 'right'
		}
	},
	cardCategory: {
		marginTop: '10px',
		color: grayColor[0] + ' !important',
		textAlign: 'center'
	},
	description: {
		color: grayColor[0]
	},
	updateProfileButton: {
		float: 'right'
	},
	center: {
		textAlign: 'center'
	},
	right: {
		textAlign: 'right'
	},
	left: {
		textAlign: 'left'
	},
	marginRight: {
		marginRight: '5px'
	},
	modalSectionTitle: {
		marginTop: '30px'
	},
	button: {
		margin: '5px'
	},
	...modalStyle(theme)
})
export default badgeStyles
