// ################################################################################
// Imports
// ################################################################################
import Buttons from "views/Components/Buttons.jsx"
import Calendar from "views/Calendar/Calendar.jsx"
import Charts from "views/Charts/Charts.jsx"
import Dashboard from "views/Dashboard/Dashboard.jsx"
import ErrorPage from "views/Pages/ErrorPage.jsx"
import ExtendedForms from "views/Forms/ExtendedForms.jsx"
import ExtendedTables from "views/Tables/ExtendedTables.jsx"
import FullScreenMap from "views/Maps/FullScreenMap.jsx"
import GoogleMaps from "views/Maps/GoogleMaps.jsx"
import GridSystem from "views/Components/GridSystem.jsx"
import Icons from "views/Components/Icons.jsx"
import LockScreenPage from "views/Pages/LockScreenPage.jsx"
import LoginPage from "views/Pages/LoginPage.jsx"
import Notifications from "views/Components/Notifications.jsx"
import Panels from "views/Components/Panels.jsx"
import PricingPage from "views/Pages/PricingPage.jsx"
import RTLSupport from "views/Pages/RTLSupport.jsx"
import ReactTables from "views/Tables/ReactTables.jsx"
import RegisterPage from "views/Pages/RegisterPage.jsx"
import RegularForms from "views/Forms/RegularForms.jsx"
import RegularTables from "views/Tables/RegularTables.jsx"
import SweetAlert from "views/Components/SweetAlert.jsx"
import TimelinePage from "views/Pages/Timeline.jsx"
import Typography from "views/Components/Typography.jsx"
import UserProfile from "views/Pages/UserProfile.jsx"
import ValidationForms from "views/Forms/ValidationForms.jsx"
import VectorMap from "views/Maps/VectorMap.jsx"
import Widgets from "views/Widgets/Widgets.jsx"
import Wizard from "views/Forms/Wizard.jsx"

// @material-ui/icons
import Apps from "@material-ui/icons/Apps"
import DashboardIcon from "@material-ui/icons/Dashboard"
import DateRange from "@material-ui/icons/DateRange"
import GridOn from "@material-ui/icons/GridOn"
import Image from "@material-ui/icons/Image"
import EditIcon from "@material-ui/icons/Edit"
import CreateIcon from "@material-ui/icons/LibraryAdd"
import BackpackIcon from "@material-ui/icons/ShoppingBasket"
import BadgeIcon from "@material-ui/icons/Stars"
import UserIcon from "@material-ui/icons/Face"
import Place from "@material-ui/icons/Place"
import Timeline from "@material-ui/icons/Timeline"
import WidgetsIcon from "@material-ui/icons/Widgets"
import AboutIcon from "@material-ui/icons/Info"
import ContactMailIcon from "@material-ui/icons/ContactMail"

// ################################################################################
// Badger Views
// ################################################################################
import Badges from "views/Badges/Badges.jsx"
import Users from "views/Users/Users.jsx"
import ContactUs from "views/ContactUs/ContactUs"
// import MyTimeline from "views/MyTimeline/MyTimeline.jsx";
import Backpack from "views/Backpack/Backpack.jsx"
import Badge from "views/Badge/Badge.jsx"
// import RoadMap from "views/RoadMap/RoadMap";
import CreateBadge from "views/CreateBadge/CreateBadge"
import EditBadge from "views/EditBadge/EditBadge"
import Assertion from "views/Assertion/Assertion"
import User from "views/User/User"
import Assertions from "views/Assertions/Assertions"
// import Reports from "views/Reports/Reports";
import EditAssertion from "./views/EditAssertion/EditAssertion"
import About from "views/About/About.jsx"
// ################################################################################
// Routes
// ################################################################################
var dashRoutes = [
    {
        path: "/about",
        name: "About",
        icon: AboutIcon,
        component: About,
        layout: "/admin",
        accessControl: ["User", "Admin", "dev"]
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
        accessControl: ["User", "Admin", "dev"]
    },
    {
        path: "/backpack",
        name: "My Backpack",
        icon: BackpackIcon,
        component: Backpack,
        layout: "/admin",
        accessControl: ["User", "Admin"]
    },
    // {
    //   path: "/mytimeline",
    //   name: "My Timeline",
    //   icon: DashboardIcon,
    //   component: MyTimeline,
    //   layout: "/admin",
    //   accessControl: ["User", "dev"]
    // },
    {
        path: "/badges",
        name: "Badges",
        icon: BadgeIcon,
        component: Badges,
        layout: "/admin",
        accessControl: ["User", "Admin", "dev"]
    },
    // {
    //   path: "/roadmap",
    //   name: "RoadMap",
    //   icon: DashboardIcon,
    //   component: RoadMap,
    //   layout: "/admin",
    //   accessControl: ["User", "Admin", "dev"]
    // },
    {
        path: "/create-badge",
        name: "Create Badge",
        icon: CreateIcon,
        component: CreateBadge,
        layout: "/admin",
        accessControl: ["Admin", "dev"]
    },
    {
        path: "/edit-badge",
        name: "Edit Badge",
        icon: EditIcon,
        component: EditBadge,
        layout: "/admin",
        accessControl: []
    },
    {
        path: "/edit-assertion",
        name: "Edit Assertion",
        icon: EditIcon,
        component: EditAssertion,
        layout: "/admin",
        accessControl: []
    },
    {
        path: "/assertions",
        name: "Assertions",
        icon: DashboardIcon,
        component: Assertions,
        layout: "/admin",
        accessControl: ["Admin", "dev"]
    },
    {
        path: "/assertion",
        name: "Assertion",
        icon: DashboardIcon,
        component: Assertion,
        layout: "/admin",
        accessControl: []
    },
    // {
    //   path: "/reports",
    //   name: "Reports",
    //   icon: DashboardIcon,
    //   component: Reports,
    //   layout: "/admin",
    //   accessControl: ["User", "Admin", "dev"]
    // },
    {
        path: "/users",
        name: "Users",
        icon: UserIcon,
        component: Users,
        layout: "/admin",
        accessControl: ["Admin"]
    },
    {
        path: "/contactus",
        name: "Contact Us",
        icon: ContactMailIcon,
        component: ContactUs,
        layout: "/admin",
        accessControl: ["User", "Admin", "dev"]
    },
    {
        path: "/user",
        name: "User",
        icon: DashboardIcon,
        component: User,
        layout: "/admin",
        accessControl: []
    },
    {
        path: "/badge",
        name: "Badge",
        icon: DashboardIcon,
        component: Badge,
        layout: "/admin",
        accessControl: []
    },
    {
        collapse: true,
        name: "Pages",
        icon: Image,
        state: "pageCollapse",
        accessControl: ["dev"],
        views: [
            {
                path: "/pricing-page",
                name: "Pricing Page",
                mini: "PP",
                component: PricingPage,
                layout: "/auth",
                accessControl: ["dev"]
            },
            {
                path: "/rtl-support-page",
                name: "RTL Support",
                mini: "RS",
                component: RTLSupport,
                layout: "/rtl",
                accessControl: ["dev"]
            },
            {
                path: "/timeline-page",
                name: "Timeline Page",
                mini: "T",
                component: TimelinePage,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/login-page",
                name: "Login Page",
                mini: "L",
                component: LoginPage,
                layout: "/auth",
                accessControl: ["dev"]
            },
            {
                path: "/register-page",
                name: "Register Page",
                mini: "R",
                component: RegisterPage,
                layout: "/auth",
                accessControl: ["dev"]
            },
            {
                path: "/lock-screen-page",
                name: "Lock Screen Page",
                mini: "LS",
                component: LockScreenPage,
                layout: "/auth",
                accessControl: ["dev"]
            },
            {
                path: "/user-page",
                name: "User Profile",
                mini: "UP",
                component: UserProfile,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/error-page",
                name: "Error Page",
                mini: "E",
                component: ErrorPage,
                layout: "/auth",
                accessControl: ["dev"]
            }
        ]
    },
    {
        collapse: true,
        name: "Components",
        icon: Apps,
        state: "componentsCollapse",
        accessControl: ["dev"],
        views: [
            {
                collapse: true,
                name: "Multi Level Collapse",
                mini: "MC",
                state: "multiCollapse",
                accessControl: ["dev"],
                views: [
                    {
                        path: "/buttons",
                        name: "Buttons",
                        mini: "B",
                        component: Buttons,
                        layout: "/admin",
                        accessControl: ["dev"]
                    }
                ]
            },
            {
                path: "/buttons",
                name: "Buttons",
                mini: "B",
                component: Buttons,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/grid-system",
                name: "Grid System",
                mini: "GS",
                component: GridSystem,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/panels",
                name: "Panels",
                mini: "P",
                component: Panels,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/sweet-alert",
                name: "Sweet Alert",
                mini: "SA",
                component: SweetAlert,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/notifications",
                name: "Notifications",
                mini: "N",
                component: Notifications,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/icons",
                name: "Icons",
                mini: "I",
                component: Icons,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/typography",
                name: "Typography",
                mini: "T",
                component: Typography,
                layout: "/admin",
                accessControl: ["dev"]
            }
        ]
    },
    {
        collapse: true,
        name: "Forms",
        icon: "content_paste",
        state: "formsCollapse",
        accessControl: ["dev"],
        views: [
            {
                path: "/regular-forms",
                name: "Regular Forms",
                mini: "RF",
                rtlMini: "صو",
                component: RegularForms,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/extended-forms",
                name: "Extended Forms",
                mini: "EF",
                rtlMini: "هوو",
                component: ExtendedForms,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/validation-forms",
                name: "Validation Forms",
                mini: "VF",
                rtlMini: "تو",
                component: ValidationForms,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/wizard",
                name: "Wizard",
                mini: "W",
                rtlMini: "ث",
                component: Wizard,
                layout: "/admin",
                accessControl: ["dev"]
            }
        ]
    },
    {
        collapse: true,
        name: "Tables",
        icon: GridOn,
        state: "tablesCollapse",
        accessControl: ["dev"],
        views: [
            {
                path: "/regular-tables",
                name: "Regular Tables",
                mini: "RT",
                component: RegularTables,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/extended-tables",
                name: "Extended Tables",
                mini: "ET",
                component: ExtendedTables,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/react-tables",
                name: "React Tables",
                mini: "RT",
                component: ReactTables,
                layout: "/admin",
                accessControl: ["dev"]
            }
        ]
    },
    {
        collapse: true,
        name: "Maps",
        icon: Place,
        state: "mapsCollapse",
        accessControl: ["dev"],
        views: [
            {
                path: "/google-maps",
                name: "Google Maps",
                mini: "GM",
                component: GoogleMaps,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/full-screen-maps",
                name: "Full Screen Map",
                mini: "FSM",
                component: FullScreenMap,
                layout: "/admin",
                accessControl: ["dev"]
            },
            {
                path: "/vector-maps",
                name: "Vector Map",
                mini: "VM",
                component: VectorMap,
                layout: "/admin",
                accessControl: ["dev"]
            }
        ]
    },
    {
        path: "/widgets",
        name: "Widgets",
        icon: WidgetsIcon,
        component: Widgets,
        layout: "/admin",
        accessControl: ["dev"]
    },
    {
        path: "/charts",
        name: "Charts",
        icon: Timeline,
        component: Charts,
        layout: "/admin",
        accessControl: ["dev"]
    },
    {
        path: "/calendar",
        name: "Calendar",
        icon: DateRange,
        component: Calendar,
        layout: "/admin",
        accessControl: ["dev"]
    }
]
export default dashRoutes
