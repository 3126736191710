import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import withStyles from "@material-ui/core/styles/withStyles"

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle"

function Footer({ ...props }) {
    const { classes, fluid, white } = props
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    })

    return (
        <footer className={classes.footer}>
            <div className={container}>
                <div className={classes.left}></div>
                <p className={classes.right}>Version: {process.env.REACT_APP_VERSION} </p>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    fluid: PropTypes.bool,
    white: PropTypes.bool
}

export default withStyles(footerStyle)(Footer)
