import {
	cardTitle,
	grayColor
} from 'assets/jss/material-dashboard-pro-react.jsx'

const UserStyles = {
	...cardTitle,
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		'& small': {
			fontSize: '80%',
			fontWeight: '400'
		}
	},
	card: {
		margin: '15px'
	},
	badgeImage: {
		textAlign: 'center',
		margin: 10,
		height: 200,
		width: 200
	},
	labelHorizontal: {
		// color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: '14px',
		lineHeight: '1.428571429',
		fontWeight: '400',
		paddingTop: '39px',
		marginRight: '0',
		'@media (min-width: 992px)': {
			float: 'right'
		}
	},
	cardCategory: {
		marginTop: '10px',
		color: grayColor[0] + ' !important',
		textAlign: 'center'
	},
	description: {
		color: grayColor[0]
	},
	updateProfileButton: {
		float: 'right'
	}
}
export default UserStyles
