import React, { useContext, useEffect, useState } from 'react'

import ChartistGraph from 'react-chartist'

import withStyles from '@material-ui/core/styles/withStyles'

import Timeline from '@material-ui/icons/Timeline'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'

import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import moment from 'moment'
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import BadgeContext from '../../context/badge-context'

import { getBadgeMetadata } from 'api/Badges'
import { getUserMetadata } from 'api/Users'
import { getAssertionMetadata } from 'api/Assertions'

const Dashboard = props => {
	const globalState = useContext(BadgeContext)
	const [hasLoaded, setHasLoaded] = useState(false)
	const { classes } = props

	useEffect(() => {
		getBadgeMetadata(globalState.jwtToken).then(response => {
			globalState.setBadgeMetadata(response)
		})
		getUserMetadata(globalState.jwtToken).then(response => {
			globalState.setUserMetadata(response)
		})
		getAssertionMetadata(globalState.jwtToken).then(response => {
			globalState.setAssertionMetadata(response)
		})
		setHasLoaded(true)
	}, [])

	// TODO: Document this functions. Its a mess
	const createDataset = dataset => {
		if (!dataset.created > 0) {
			return null
		}

		var currentDate = new Date()

		var data = {
			labels: [],
			series: [[]]
		}

		var graphDates = []

		for (let index = 0; index < 12; index++) {
			data.labels.unshift(
				currentDate.toLocaleString('en-us', { month: 'short' })
			)
			graphDates.push(moment(currentDate).format('YYYY-MM'))
			currentDate.setMonth(currentDate.getMonth() - 1)
		}

		currentDate = new Date()
		var yearAgo = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		)
		yearAgo.setMonth(currentDate.getMonth() - 12)

		dataset.created.forEach(date => {
			var itemDate = new Date(date)
			if (itemDate >= yearAgo) {
				graphDates.push(moment(itemDate).format('YYYY-MM'))
			}
		})

		var graphData = arrayOccurrence(graphDates)

		for (let index = 0; index < graphData[1].length; index++) {
			graphData[1][index] = graphData[1][index] - 1
		}

		// // Generate series
		var count = dataset.created.length
		for (let index = 0; index < 12; index++) {
			data.series[0].unshift(count)
			count -= graphData[1][graphData[1].length - 1 - index]
			count = count < 0 ? 0 : count
		}
		return data
	}

	//Returns the number of occurrences within an array
	function arrayOccurrence(arr) {
		var a = [],
			b = [],
			prev

		arr.sort()
		for (var i = 0; i < arr.length; i++) {
			if (arr[i] !== prev) {
				a.push(arr[i])
				b.push(1)
			} else {
				b[b.length - 1]++
			}
			prev = arr[i]
		}

		return [a, b]
	}

	function Graph(props) {
		// TODO: Check if prop.data etc exist before processing
		// Alternatively that could be done in the main return
		if (hasLoaded) {
			return (
				<GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader>
							<h4 className={classes.cardIconTitle}>
								{props.title}
							</h4>
						</CardHeader>
						<CardBody>
							<ChartistGraph
								data={createDataset(props.data)}
								type="Line"
								options={{
									axisY: {
										showGrid: true,
										offset: 40,
										onlyInteger: true
									},
									axisX: {
										showGrid: true
									},
									low: 0,
									showPoint: true,
									height: '300px'
								}}
							/>
						</CardBody>
						<CardFooter chart>
							<div className={classes.stats}>
								<Timeline /> Total Count: {props.data.count}
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			)
		} else {
			return null
		}
	}

	return (
		<GridContainer>
			<Graph
				title="Badges Issued"
				data={globalState.assertionMetadata}
			></Graph>
			<Graph
				title="Available Badges"
				data={globalState.badgeMetadata}
			></Graph>
			<Graph title="Total Users" data={globalState.userMetadata}></Graph>
		</GridContainer>
	)
}

export default withStyles(dashboardStyle)(Dashboard)
