import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
// import CardHeader from "components/Card/CardHeader.jsx";
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import withStyles from "@material-ui/core/styles/withStyles"
import AddAlert from "@material-ui/icons/AddAlert"
import DownloadIcon from "@material-ui/icons/CloudDownload"
import RefreshIcon from "@material-ui/icons/Refresh"
import badgeImage from "assets/img/badges/badge-placeholder.png"
import userImage from "assets/img/default-avatar.png"
import TextField from "@material-ui/core/TextField"
import Snackbar from "components/Snackbar/Snackbar.jsx"
import matchSorter from "match-sorter"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { NavLink } from "react-router-dom"
import ReactTable from "react-table"
import UserStyles from "views/User/UserStyles"
import { getUser, getUserAssertions } from "../../api/Users"
import BadgeContext from "../../context/badge-context"
import Tag from "components/Badge/Badge.jsx"
import ViewIcon from "@material-ui/icons/Pageview"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
const User = props => {
    const GC = useContext(BadgeContext)
    const userId = props.location.pathname.split("user/")[1]
    const [user, setUser] = useState("")
    const [userAssertions, setUserAssertions] = useState(null)
    // const [isBadgeInWishlist, setIsBadgeInWishlist] = useState(
    //   GC.wishlist.includes(badge.pk)
    // );
    const [] = useState("card")
    const [notification, setNotification] = useState({
        open: false,
        message: "",
        color: "info"
    })
    const { classes } = props
    const [] = useState(false)
    const [] = useState(false)

    // Run only once
    useEffect(() => {
        if (GC.jwtToken) {
            getUser(GC.jwtToken, userId).then(user => {
                setUser(user)
            })

            if (GC.userRole === "Admin") {
                fetchAssertions()
            }
        }
    }, [GC.jwtToken])

    function fetchAssertions() {
        getUserAssertions(GC.jwtToken, userId).then(res => {
            setUserAssertions(res)
        })
    }

    const notificationPanels = (
        <Snackbar
            place="tr"
            color={notification.color}
            icon={AddAlert}
            message={notification.message}
            open={notification.open}
            closeNotification={() => setNotification({ open: false })}
            close
        />
    )

    function UserDisplay() {
        if (user) {
            return (
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardHeader
                            title="User"
                            // TODO: Enable user edit when ready
                            // action={
                            // 	<NavLink
                            // 		to={{
                            // 			pathname: '/admin/edit-user/' + userId
                            // 		}}
                            // 	>
                            // 		<IconButton
                            // 			color="default"
                            // 			className={classes.button}
                            // 			size="small"
                            // 			edge="end"
                            // 		>
                            // 			<EditIcon />
                            // 		</IconButton>
                            // 	</NavLink>
                            // }
                        />

                        <CardContent>
                            <Grid container justify="center" alignItems="center">
                                <Avatar alt={user.email} src={userImage} className={classes.badgeImage} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={user.email}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="issueCount"
                                    name="issueCount"
                                    label="Issue Count"
                                    value={user.issueCount}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="created"
                                    name="created"
                                    label="Created"
                                    value={new Date(user.created).toLocaleDateString()}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="lastUpdated"
                                    name="lastUpdated"
                                    label="Last Updated"
                                    value={new Date(user.lastUpdated).toLocaleDateString()}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="lastUpdated"
                                    name="lastUpdated"
                                    label="Last Issued"
                                    value={new Date(user.lastIssued).toLocaleDateString()}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    id="lastLoggedIn"
                                    name="lastLoggedIn"
                                    label="Last Logged In"
                                    value={new Date(user.lastLoggedIn).toLocaleDateString()}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            {/* 
						

							
							<GridItem xs={12} sm={2}>
								<FormLabel
									className={classes.labelHorizontal}
								>
									Created
								</FormLabel>
							</GridItem>
							<GridItem xs={12} sm={10}>
								<CustomInput
									labelText={new Date(
										user.created
									).toLocaleDateString()}
									id="disabled"
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										disabled: true
									}}
								/>
							</GridItem>
						

							
							<GridItem xs={12} sm={2}>
								<FormLabel
									className={classes.labelHorizontal}
								>
									Modified
								</FormLabel>
							</GridItem>
							<GridItem xs={12} sm={10}>
								<CustomInput
									labelText={new Date(
										user.lastUpdated
									).toLocaleDateString()}
									id="disabled"
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										disabled: true
									}}
								/>
							</GridItem> */}
                        </CardContent>
                    </Card>
                </Grid>
            )
        } else {
            return null
        }
    }

    const addDefaultSrc = e => {
        e.target.src = badgeImage
    }

    function Assertions() {
        if (user && userAssertions) {
            return (
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardHeader
                            action={
                                <React.Fragment>
                                    <CSVLink
                                        data={userAssertions}
                                        filename={
                                            moment().format("YYYY-MM-DDTHH-mm-ss") +
                                            "-badger-export-assertions-" +
                                            user.email.split("@")[0] +
                                            ".csv"
                                        }
                                    >
                                        <IconButton aria-label="Download">
                                            <DownloadIcon />
                                        </IconButton>
                                    </CSVLink>

                                    {/* FIXME: Add OnClick() Data Refresh */}
                                    <IconButton aria-label="Refresh" onClick={fetchAssertions}>
                                        <RefreshIcon />
                                    </IconButton>
                                </React.Fragment>
                            }
                        >
                            {/* FIXME: Add Export Button similar to other pages */}
                            <h5>{user.email}</h5>
                        </CardHeader>
                        <CardContent>
                            <ReactTable
                                data={userAssertions}
                                filterable
                                sortable
                                defaultPageSize={20}
                                defaultSorted={[
                                    {
                                        // the sorting model for the table
                                        id: "created",
                                        desc: true
                                    }
                                ]}
                                className="-striped -highlight"
                                columns={[
                                    {
                                        accessor: "image",
                                        maxWidth: 100,
                                        filterable: false,
                                        sortable: false,
                                        Cell: row => (
                                            <Grid container justify="center" alignItems="center">
                                                <img
                                                    src={`${GC.badgeBucketOriginal}/${
                                                        row.original.d2.split(":")[1]
                                                    }.png`}
                                                    onError={addDefaultSrc}
                                                    alt="Badge"
                                                    style={{ maxWidth: "100%" }}
                                                />
                                            </Grid>
                                        )
                                    },
                                    {
                                        Header: "Badge",
                                        accessor: "badge",
                                        // maxWidth: 200,
                                        //sortable: false,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["email"] }),
                                        filterAll: true,
                                        Filter: ({ filter, onChange }) => {
                                            return (
                                                <TextField
                                                    id="searching email"
                                                    onChange={event => onChange(event.target.value)}
                                                    value={filter ? filter.value : ""}
                                                    placeholder="Search"
                                                    fullWidth
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon color="disabled" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )
                                        }
                                    },
                                    {
                                        Header: "Created",
                                        accessor: "created",
                                        maxWidth: 100,
                                        sortable: true,
                                        filterable: false,
                                        Cell: row => new Date(row.original.created).toLocaleDateString()
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "status",
                                        maxWidth: 100,
                                        sortable: true,
                                        filterable: false,
                                        Cell: row => {
                                            if ("status" in row.original) {
                                                if (row.original.status.toLowerCase() == "awarded") {
                                                    return <Tag color="success">{row.original.status}</Tag>
                                                } else if (row.original.status.toLowerCase() == "rework") {
                                                    return <Tag color="danger">{row.original.status}</Tag>
                                                } else {
                                                    return <Tag color="warning">{row.original.status}</Tag>
                                                }
                                            } else {
                                                return null
                                            }
                                        }
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions",
                                        maxWidth: 70,
                                        sortable: false,
                                        filterable: false,
                                        // minWidth: 40,
                                        Cell: row => (
                                            <NavLink
                                                to={{
                                                    pathname: "/admin/assertion/" + row.original.pk.split(":")[1]
                                                }}
                                            >
                                                <IconButton
                                                    color="default"
                                                    className={classes.button}
                                                    size="small"
                                                    edge="end"
                                                >
                                                    <ViewIcon />
                                                </IconButton>
                                            </NavLink>
                                        )
                                    }
                                ]}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )
        } else {
            return null
        }
    }

    // ################################################################################
    // Main Return
    // ################################################################################

    return (
        <Grid container>
            {/* {GetDisplay(displayType)} */}

            <UserDisplay />

            <Assertions title="Badges" />

            {notificationPanels}
        </Grid>
    )
}

export default withStyles(UserStyles)(User)
