import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import AddAlert from "@material-ui/icons/AddAlert";
import IconOpenInNew from "@material-ui/icons/OpenInNew";
import SaveIcon from "@material-ui/icons/Save";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import React, { useContext, useState } from "react";

import CreateBadgeStyles from "views/CreateBadge/CreateBadgeStyles";
import { addBadge } from "../../api/Badges";
import BadgeContext from "../../context/badge-context";

const CreateBadge = props => {
    const globalContext = useContext(BadgeContext);
    const [badgeName, setBadgeName] = useState("");
    const [badgeDescription, setBadgeDescription] = useState("");
    const [badgeLink, setBadgeLink] = useState("");
    const [badgeImage, setBadgeImage] = useState("");
    const [badgeCriteria, setBadgeCriteria] = useState("");
    const [badgeType, setBadgeType] = useState("community");
    const [evidenceRequired, setEvidenceRequired] = useState(false);
    const [requestable, setRequestable] = useState(true);

    const [badgeOwners, setBadgeOwners] = useState(globalContext.userEmail);
    const [badgeReviewers, setBadgeReviewers] = useState("");

    const [notification, setNotification] = useState({
        open: false,
        message: "",
        color: ""
    });
    const { classes } = props;

    // ################################################################################
    // Functions
    // ################################################################################
    const handleNotification = notification => {
        if (!notification.open) {
            setNotification({
                open: true,
                message: notification.message,
                icon: notification.icon,
                color: notification.color
            });
            setTimeout(
                function() {
                    setNotification({ open: false });
                }.bind(this),
                3000
            );
        }
    };

    function openUrlInNewWindow(url) {
        window.open(url, "_blank").focus();
    }

    const notificationPanels = (
        <Snackbar
            place="tr"
            color={notification.color}
            icon={AddAlert}
            message={notification.message}
            open={notification.open}
            closeNotification={() => setNotification({ open: false })}
            close
        />
    );

    //#################################################################
    // Buttons
    //#################################################################

    // TODO: Handle Error Checking
    const handleSubmit = () => {
        var newBadge = {
            name: badgeName,
            description: badgeDescription,
            link: badgeLink,
            type: badgeType,
            criteria: badgeCriteria,
            evidenceRequired: evidenceRequired,
            requestable: requestable,
            owners: badgeOwners
                .replace(/\s/g, "")
                .replace(/,$/, "")
                .split(","),
            reviewers: badgeReviewers
                .replace(/\s/g, "")
                .replace(/,$/, "")
                .split(",")
        };

        newBadge["imageData"] = badgeImage;

        if (globalContext.jwtToken) {
            addBadge(globalContext.jwtToken, newBadge)
                .then(res => {
                    handleNotification({
                        color: "success",
                        icon: "AddAlert",
                        message: "Added Badge: " + res.data.name
                    });
                })
                .catch(err => {
                    console.error(err.response);
                    handleNotification({
                        color: "danger",
                        icon: "AddAlert",
                        message: err.response.data.errorMessage.message
                    });
                });
        }
    };

    const handleSetBadgeImage = imageFile => {
        if (imageFile) {
            setBadgeImage(imageFile);
        } else {
            setBadgeImage("");
        }
    };

    function GetDisplay() {
        return (
            <Grid container>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardHeader title="Create Badge" />

                        <CardContent>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <ImageUpload
                                        addButtonProps={{
                                            color: "warning",
                                            round: true
                                        }}
                                        changeButtonProps={{
                                            color: "rose",
                                            round: true
                                        }}
                                        removeButtonProps={{
                                            color: "danger",
                                            round: true
                                        }}
                                        getFile={filename =>
                                            handleSetBadgeImage(filename)
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="name"
                                        name="name"
                                        label="Name"
                                        value={badgeName}
                                        onChange={event =>
                                            setBadgeName(event.target.value)
                                        }
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="description"
                                    name="description"
                                    label="Description"
                                    value={badgeDescription}
                                    onChange={event =>
                                        setBadgeDescription(event.target.value)
                                    }
                                    multiline
                                    rows="4"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="criteria"
                                    name="criteria"
                                    label="Criteria"
                                    value={badgeCriteria}
                                    onChange={event =>
                                        setBadgeCriteria(event.target.value)
                                    }
                                    multiline
                                    rows="4"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="link"
                                    name="link"
                                    label="Link"
                                    value={badgeLink}
                                    onChange={event =>
                                        setBadgeLink(event.target.value)
                                    }
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    helperText="eg. https://www.example-link.com/badge-details"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="openInNewWindow"
                                                    onClick={() =>
                                                        openUrlInNewWindow(
                                                            badgeLink
                                                        )
                                                    }
                                                >
                                                    <IconOpenInNew />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                >
                                    <InputLabel
                                        // ref={inputLabel}
                                        htmlFor="evidenceRequired"
                                    >
                                        Evidence Required
                                    </InputLabel>
                                    <Select
                                        native
                                        value={evidenceRequired}
                                        onChange={event =>
                                            setEvidenceRequired(
                                                event.target.value
                                            )
                                        }
                                        inputProps={{
                                            name: "evidenceRequired",
                                            id: "evidenceRequired"
                                        }}
                                        labelWidth={0}
                                    >
                                        <option hidden value="" />
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                >
                                    <InputLabel
                                        // ref={inputLabel}
                                        htmlFor="requestable"
                                    >
                                        User Requestable
                                    </InputLabel>
                                    <Select
                                        native
                                        value={requestable}
                                        onChange={event =>
                                            setRequestable(event.target.value)
                                        }
                                        inputProps={{
                                            name: "requestable",
                                            id: "requestable"
                                        }}
                                        labelWidth={0}
                                    >
                                        <option hidden value="" />
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                >
                                    <InputLabel
                                        // ref={inputLabel}
                                        htmlFor="badgeType"
                                    >
                                        Type
                                    </InputLabel>
                                    <Select
                                        native
                                        value={badgeType}
                                        onChange={event =>
                                            setBadgeType(event.target.value)
                                        }
                                        inputProps={{
                                            name: "badgeType",
                                            id: "badgeType"
                                        }}
                                        labelWidth={0}
                                    >
                                        <option hidden value="" />
                                        <option value={"open"}>
                                            Open Badge
                                        </option>
                                        <option value={"community"}>
                                            Community Badge
                                        </option>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="owners"
                                    name="owners"
                                    label="Owners"
                                    value={badgeOwners}
                                    onChange={event =>
                                        setBadgeOwners(event.target.value)
                                    }
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    variant="outlined"
                                    helperText="eg. user@email.com, anotherUser@email.com, yetAnotherUser@email.com"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="reviewers"
                                    name="reviewers"
                                    label="Reviewers"
                                    value={badgeReviewers}
                                    onChange={event =>
                                        setBadgeReviewers(event.target.value)
                                    }
                                    fullWidth
                                    multiline
                                    margin="normal"
                                    variant="outlined"
                                    helperText="eg. user@email.com, anotherUser@email.com, yetAnotherUser@email.com"
                                />
                            </Grid>

                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitButton}
                                    onClick={e => handleSubmit(e)}
                                    fullWidth
                                >
                                    <SaveIcon />
                                    Create Badge
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
    // ################################################################################
    // Main Return
    // ################################################################################

    return (
        <Grid container>
            {GetDisplay()}

            {notificationPanels}
        </Grid>
    );
};

export default withStyles(CreateBadgeStyles)(CreateBadge);
