import React, { useState, useReducer } from 'react'
import { CircularProgress, Grid, CardContent, Card } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import passwordValidator from 'password-validator'
import InvalidPassword from '@material-ui/icons/Cancel'
import ValidPassword from '@material-ui/icons/CheckCircle'

const initialFormState = {
    username: '',
    password: '',
    confirmationCode: '',
    new_password: '',
}

// Create and setup password schema
var schema = new passwordValidator()
schema.is().min(8)
schema.has().uppercase()
schema.has().lowercase()
schema.has().digits()
schema
    .has()
    .not()
    .spaces()
schema.is().symbols(['!', '#', '@', '$', '&', '%', '^', '(', ')', '+', '-', '=', '[', ']', '*'])

function reducer(state, action) {
    switch (action.type) {
        case 'updateFormState':
            return {
                ...state,
                [action.e.target.name]: action.e.target.value,
            }
        default:
            return state
    }
}

async function signUp({ username, password }, updateFormType) {
    await Auth.signUp({ username, password })
        .then(data => {
            console.log(data)
            updateFormType('confirmSignUp')
        })
        .catch(err => console.log(err))
}

async function confirmSignUp({ username, confirmationCode }, updateFormType) {
    updateFormType('signIn')
}

async function forgotPassword({ username }, updateFormType) {
    await Auth.forgotPassword(username)
        .then(data => {
            console.log(data)
            updateFormType('forgotPasswordSubmit')
        })
        .catch(err => console.log(err))
}

export default function CustomAuthLanding() {
    const [formType, updateFormType] = useState('signIn')
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [formState, updateFormState] = useReducer(reducer, initialFormState)
    const [passwordPolicy, setPasswordPolicy] = useState([
        'min',
        'uppercase',
        'lowercase',
        'symbols',
        'spaces',
        'digits',
    ])
    async function forgotPasswordSubmit({ username, confirmationCode, new_password }, updateFormType) {
        setIsLoading(true)
        await Auth.forgotPasswordSubmit(username, confirmationCode, new_password)
            .then(data => {
                console.log(data)
                updateFormType('signIn')
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                if ('log' in err) {
                    setError(err.log)
                } else if ('message' in err) {
                    setError(err.message)
                }
                setIsLoading(false)
            })
    }

    async function signIn({ username, password }) {
        setIsLoading(true)
        await Auth.signIn(username, password)
            .then(data => {
                console.log(data)
                setIsLoading(false)
            })
            .catch(err => {
                if ('log' in err) {
                    setError(err.log)
                } else if ('message' in err) {
                    setError(err.message)
                }
                setIsLoading(false)
            })
    }

    function renderForm() {
        switch (formType) {
            case 'signUp':
                return (
                    <SignUp
                        signUp={() => signUp(formState, updateFormType)}
                        setPasswordPolicy={password => setPasswordPolicy(schema.validate(password, { list: true }))}
                        passwordPolicy={passwordPolicy}
                        updateFormState={e => updateFormState({ type: 'updateFormState', e })}
                    />
                )
            case 'confirmSignUp':
                return (
                    <ConfirmSignUp
                        confirmSignUp={() => confirmSignUp(formState, updateFormType)}
                        updateFormState={e => updateFormState({ type: 'updateFormState', e })}
                    />
                )
            case 'forgotPassword':
                return (
                    <ForgotPassword
                        forgotPassword={() => forgotPassword(formState, updateFormType)}
                        updateFormState={e => updateFormState({ type: 'updateFormState', e })}
                    />
                )
            case 'forgotPasswordSubmit':
                return (
                    <ForgotPasswordSubmit
                        setPasswordPolicy={password => setPasswordPolicy(schema.validate(password, { list: true }))}
                        passwordPolicy={passwordPolicy}
                        error={error}
                        loading={isLoading}
                        forgotPasswordSubmit={() => forgotPasswordSubmit(formState, updateFormType)}
                        updateFormState={e => updateFormState({ type: 'updateFormState', e })}
                    />
                )
            case 'signIn':
                return (
                    <SignIn
                        signIn={() => signIn(formState)}
                        error={error}
                        loading={isLoading}
                        updateFormState={e => updateFormState({ type: 'updateFormState', e })}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item className={styles.inputPanel} xs={2.5}>
                <Card>
                    <CardContent>
                        <div>{renderForm(formState)}</div>
                        {formType === 'signUp' && (
                            <React.Fragment>
                                <p style={styles.footer}>
                                    Already have an account? &nbsp;
                                    <span style={styles.anchor} onClick={() => updateFormType('signIn')}>
                                        Sign In
                                    </span>
                                </p>
                            </React.Fragment>
                        )}
                        {formType === 'signIn' && (
                            <React.Fragment>
                                <p style={styles.footer}>
                                    Need an account? &nbsp;
                                    <span style={styles.anchor} onClick={() => updateFormType('signUp')}>
                                        Sign Up
                                    </span>
                                </p>

                                <p style={styles.footer}>
                                    Forgot your password? &nbsp;
                                    <span style={styles.anchor} onClick={() => updateFormType('forgotPassword')}>
                                        ForgotPassword
                                    </span>
                                </p>
                            </React.Fragment>
                        )}
                        {formType === 'forgotPassword' && (
                            <p style={styles.footer}>
                                Already have an account? &nbsp;
                                <span style={styles.anchor} onClick={() => updateFormType('signIn')}>
                                    Sign In
                                </span>
                            </p>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

function SignUp(props) {
    let isValidPassword = props.passwordPolicy.length > 0 ? 'passwordInvalid' : 'passwordValid'

    function onFormSubmit(e) {
        e.preventDefault()
        if (props.passwordPolicy.length == 0) {
            props.signUp()
        }
    }

    return (
        <form onSubmit={onFormSubmit}>
            <div style={styles.container}>
                <input
                    name="username"
                    onChange={e => {
                        e.persist()
                        props.updateFormState(e)
                    }}
                    style={styles.input}
                    placeholder="email"
                />
                <input
                    type="password"
                    name="password"
                    onChange={e => {
                        e.persist()
                        props.setPasswordPolicy(e.target.value)
                        props.updateFormState(e)
                    }}
                    style={{
                        ...styles.input,
                        ...styles[isValidPassword],
                    }}
                    placeholder="password"
                />
                <button type="submit" style={styles.button}>
                    Sign Up
                </button>
                <PasswordPolicy passwordPolicy={props.passwordPolicy} />
            </div>
        </form>
    )
}

function UserFeedback(props) {
    return <p style={{ color: 'red', textAlign: 'center' }}> {props.error} </p>
}

function PasswordPolicy(props) {
    function passwordCompliant(policyPart) {
        return props.passwordPolicy.includes(policyPart) ? 'red' : 'green'
    }

    function passwordValidationIcon(policyPart) {
        return props.passwordPolicy.includes(policyPart) ? (
            <InvalidPassword style={styles.passwordIcon} />
        ) : (
            <ValidPassword style={styles.passwordIcon} />
        )
    }

    return (
        <div style={styles.passwordHint}>
            <ul>
                <p style={{ color: passwordCompliant('min') }}>
                    {passwordValidationIcon('min')} Minimum length is 8 characters
                </p>
                <p style={{ color: passwordCompliant('uppercase') }}>
                    {passwordValidationIcon('uppercase')} At least one uppercase letter (A-Z)
                </p>
                <p style={{ color: passwordCompliant('lowercase') }}>
                    {passwordValidationIcon('lowercase')} At least one lowercase letter (a-z)
                </p>
                <p style={{ color: passwordCompliant('digits') }}>
                    {passwordValidationIcon('digits')} At least one number
                </p>
                <p style={{ color: passwordCompliant('symbols') }}>
                    {passwordValidationIcon('symbols')} At least one special character
                </p>
            </ul>
        </div>
    )
}

function ForgotPassword(props) {
    return (
        <div style={styles.container}>
            <input
                name="username"
                onChange={e => {
                    e.persist()
                    props.updateFormState(e)
                }}
                style={styles.input}
                placeholder="email"
            />
            <button onClick={props.forgotPassword} style={styles.button}>
                Reset Password
            </button>
        </div>
    )
}

function ForgotPasswordSubmit(props) {
    let isValidPassword = props.passwordPolicy.length > 0 ? 'passwordInvalid' : 'passwordValid'

    function onFormSubmit(e) {
        e.preventDefault()
        if (props.passwordPolicy.length == 0) {
            props.forgotPasswordSubmit()
        }
    }
    return (
        <>
            <UserFeedback error={props.error} />
            <form onSubmit={onFormSubmit}>
                <div style={styles.container}>
                    {props.loading ? <CircularProgress style={{ alignContent: 'center' }} /> : null}
                    <input
                        name="username"
                        placeholder="email"
                        onChange={e => {
                            e.persist()
                            props.updateFormState(e)
                        }}
                        style={styles.input}
                    />
                    <input
                        name="confirmationCode"
                        placeholder="Confirmation Code"
                        onChange={e => {
                            e.persist()
                            props.updateFormState(e)
                        }}
                        style={styles.input}
                    />
                    <input
                        type="password"
                        name="new_password"
                        onChange={e => {
                            e.persist()
                            props.setPasswordPolicy(e.target.value)
                            props.updateFormState(e)
                        }}
                        style={{
                            ...styles.input,
                            ...styles[isValidPassword],
                        }}
                        placeholder="new password"
                    />
                    <button type="submit" style={styles.button}>
                        Confirm New Password
                    </button>
                    <PasswordPolicy passwordPolicy={props.passwordPolicy} />
                </div>
            </form>
        </>
    )
}

function SignIn(props) {
    console.log(props)

    function onFormSubmit(e) {
        e.preventDefault()
        props.signIn()
    }

    return (
        <>
            <p style={styles.footer}>Sign in with email and password</p>
            <UserFeedback error={props.error} />
            <form onSubmit={onFormSubmit}>
                <div style={styles.container}>
                    {props.loading ? <CircularProgress style={{ alignContent: 'center' }} /> : null}
                    <input
                        name="username"
                        onChange={e => {
                            e.persist()
                            props.updateFormState(e)
                        }}
                        style={styles.input}
                        placeholder="email"
                    />
                    <input
                        type="password"
                        name="password"
                        onChange={e => {
                            e.persist()
                            props.updateFormState(e)
                        }}
                        style={styles.input}
                        placeholder="password"
                    />
                    <button type="submit" style={styles.button}>
                        Sign In
                    </button>
                </div>
            </form>
        </>
    )
}

function ConfirmSignUp(props) {
    return (
        <div style={styles.container}>
            <p style={styles.footer}>Email Verification Sent</p>
            <p>
                We have sent an email verification link to your email. Please check your email, follow the instructions
                to verify your email address, and then click the button below to continue.
            </p>
            <button onClick={props.confirmSignUp} style={styles.button}>
                Continue
            </button>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        maxWidth: 400,
        flexDirection: 'column',
        marginTop: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        height: 45,
        marginTop: 8,
        width: 300,
        maxWidth: 300,
        padding: '0px 8px',
        fontSize: 16,
        outline: 'none',
        border: 'none',
        borderBottom: '2px solid rgba(0, 0, 0, .3)',
    },
    passwordInvalid: {
        borderWidth: 3,
        borderColor: 'rgb(255,0,0)',
        backgroundColor: 'rgba(255, 0, 0, .3)',
    },
    passwordValid: {
        borderWidth: 3,
        borderColor: 'rgb(0,255,0)',
        backgroundColor: 'rgba(0,255, 0, .3)',
    },
    passwordIcon: {
        fontSize: '1rem',
    },
    button: {
        backgroundColor: '#FFED00',
        color: 'black',
        width: 316,
        height: 45,
        marginTop: 10,
        fontWeight: '600',
        fontSize: 14,
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        borderRadius: 3,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, .3)',
    },
    footer: {
        fontSize: 16,
        fontWeight: '700',
        padding: '10px 10px',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 1)',
    },
    passwordHint: {
        maxWidth: 350,
    },
    anchor: {
        color: '#006bfc',
        cursor: 'pointer',
    },
}
