import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import withStyles from "@material-ui/core/styles/withStyles";
import Logout from "@material-ui/icons/ExitToApp";
import Button from "components/CustomButtons/Button.jsx";
import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {
  render() {
    const { classes } = this.props;
    const wrapper = classNames(classes.wrapperRTL);

    const signOut = () => {
      Auth.signOut()
    };

    return (
      <div className={wrapper}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          className={classes.buttonLink}
          onClick={signOut}>
          <Logout className={classes.headerLinksSvg + " " + classes.links} />
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
