import React, { useContext, useEffect, useState } from "react"

import withStyles from "@material-ui/core/styles/withStyles"

import CardBody from "components/Card/CardBody.jsx"
import cardBodyStyle from "assets/jss/material-dashboard-pro-react/components/cardBodyStyle.jsx"
import color from "@material-ui/core/colors/amber"
import Button from "@material-ui/core/Button"
import { styled } from "@material-ui/core/styles"
import { Grid, Paper, Typography, Divider } from "@material-ui/core"

const MyButton = styled(Button)({
    background: "linear-gradient( #e7e7e7 30%, #e7e7e7 90%)",
    border: 0,
    borderRadius: 5,
    //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    color: "black",
    height: 80,
    width: "100%",
    margin: "centre",
    padding: "0 60px"
})

const ContactUs = props => {
    const mailTo = "pmatheson@dxc.com?cc=skuppuswamy4@dxc.com"
    return (
        <React.Fragment>
            <Grid container justify="center" direction="column" spacing={5}>
                <Grid item xs={12}>
                    <Typography variant="h5" align="left">
                        Thank you for using Badger. Please click on one of the options that is most relevant to you. You
                        will be directed to your mailbox which will allow you to draft your own email. We will respond
                        soon.{" "}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <MyButton
                                variant="contained"
                                size="large"
                                color="gray"
                                href={`mailto:${mailTo}&Subject=Badger: Issue/Bug Report`}
                                target="_top"
                            >
                                Report an issue or bug
                            </MyButton>
                        </Grid>
                        <Grid item xs>
                            <MyButton
                                variant="contained"
                                size="large"
                                color="gray"
                                href={`mailto:${mailTo}&Subject=Badger: Feature Request`}
                                target="_top"
                            >
                                Request a feature
                            </MyButton>
                        </Grid>
                        <Grid item xs>
                            <MyButton
                                variant="contained"
                                size="large"
                                color="gray"
                                href={`mailto:${mailTo}&Subject=Badger: Feedback Other`}
                                target="_top"
                            >
                                Other
                            </MyButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default withStyles(cardBodyStyle)(ContactUs)
