import React, { useReducer, useEffect, useState, useContext } from "react";
import CustomAuthLanding from "./views/CustomAuthLanding/CustomAuthLanding";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import { Hub, Auth } from "aws-amplify";
import { createBrowserHistory } from "history";
import AdminLayout from "layouts/Admin.jsx";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import BadgeContext from "./context/badge-context";
import bgImage from "./assets/img/badger-landing-page.png";

const initialUserState = { user: null, loading: true };

function updateGlobalState(globalState, user) {
    console.log("Setting global vars");
    globalState.setUser(user);
    globalState.setJwtToken(user.signInUserSession.idToken.jwtToken);
    globalState.setUserId(
        user.signInUserSession.idToken.payload["cognito:username"]
    );
    globalState.setUserEmail(user.signInUserSession.idToken.payload.email);

    // TODO: Need to rewrite this to undertake better check
    if (user.signInUserSession.idToken.payload["cognito:groups"]) {
        globalState.setUserRole("Admin");
    } else {
        globalState.setUserRole("User");
    }
}

function App() {
    const [userState, dispatch] = useReducer(reducer, initialUserState);
    const [formState, updateFormState] = useState("base");
    const hist = createBrowserHistory();
    const globalState = useContext(BadgeContext);

    useEffect(() => {
        console.log("useEffect");
        // set listener for auth events
        Hub.listen("auth", data => {
            const { payload } = data;
            if (payload.event === "signIn") {
                updateGlobalState(globalState, payload.data);
                setImmediate(() =>
                    dispatch({ type: "setUser", user: payload.data })
                );
                console.log(payload);
                updateFormState("base");
            }
            // this listener is needed for form sign ups since the OAuth will redirect & reload
            if (payload.event === "signOut") {
                setTimeout(
                    () => dispatch({ type: "setUser", user: null }),
                    350
                );
            }
        });
        // we check for the current user unless there is a redirect to ?signedIn=true
        if (!window.location.search.includes("?signedin=true")) {
            checkUser(dispatch, globalState);
        }
    }, []);

    return (
        <div style={styles.appContainer}>
            {userState.loading && (
                <div style={styles.body}>
                    <p>Loading...</p>
                </div>
            )}
            {!userState.user && !userState.loading && (
                <div style={styles.landing}>
                    <React.Fragment>
                        <CustomAuthLanding />
                    </React.Fragment>
                </div>
            )}
            {userState.user && userState.user.signInUserSession && (
                <Router>
                    <Switch>
                        <Route path="/" component={AdminLayout} />
                        <Redirect from="/" to="/about" />
                    </Switch>
                </Router>
            )}
        </div>
    );
}

function reducer(state, action) {
    switch (action.type) {
        case "setUser":
            return { ...state, user: action.user, loading: false };
        case "loaded":
            return { ...state, loading: false };
        default:
            return state;
    }
}

async function checkUser(dispatch, globalState) {
    try {
        await Auth.currentAuthenticatedUser().then(user => {
            updateGlobalState(globalState, user);
            console.log("user: ", user);
            dispatch({ type: "setUser", user });
        });
    } catch (err) {
        console.log("err: ", err);
        dispatch({ type: "loaded" });
    }
}

function signOut() {
    Auth.signOut()
        .then(data => {
            console.log("signed out: ", data);
        })
        .catch(err => console.log(err));
}

const styles = {
    appContainer: {},
    landing: {
        paddingTop: 100,
        backgroundColor: "black",
        backgroundImage: "url(" + bgImage + ")",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        height: "100vh"
    },
    loading: {},
    button: {
        marginTop: 15,
        width: "100%",
        maxWidth: 250,
        marginBottom: 10,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0px 16px",
        borderRadius: 2,
        boxShadow: "0px 1px 3px rgba(0, 0, 0, .3)",
        cursor: "pointer",
        outline: "none",
        border: "none",
        minHeight: 40
    },
    text: {
        color: "white",
        fontSize: 14,
        marginLeft: 10,
        fontWeight: "bold"
    },
    signOut: {
        backgroundColor: "black"
    },
    footer: {
        fontWeight: "600",
        padding: "0px 25px",
        textAlign: "right",
        color: "rgba(0, 0, 0, 0.6)"
    },
    anchor: {
        color: "rgb(255, 153, 0)",
        textDecoration: "none"
    },
    body: {
        // padding: '0px 30px',
        // height: '78vh'
    }
};

export default App;
