import React, { useState } from "react"
import BadgeContext from "./badge-context"

const GlobalState = props => {
    // const [authState, setAuthState] = useState("signedOut");
    // const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState("")
    const [userId, setUserId] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userRole, setUserRole] = useState("")
    const [jwtToken, setJwtToken] = useState("")

    const [badges, setBadges] = useState([])
    const [users, setUsers] = useState([])
    const [assertions, setAssertions] = useState([])

    const [badgeMetadata, setBadgeMetadata] = useState([])
    const [assertionMetadata, setAssertionMetadata] = useState([])
    const [userMetadata, setUserMetadata] = useState([])

    const [wishlist, setWishlist] = useState([])
    const [userAssertions, setUserAssertions] = useState([])

    const badgeBucketOriginal = `https://${process.env.REACT_APP_BADGE_IMAGE_BUCKET}.s3.amazonaws.com/original`
    const badgeBucketBaked = `https://${process.env.REACT_APP_BADGE_IMAGE_BUCKET}.s3.amazonaws.com/baked`
    const apiOpenBadge = `${process.env.REACT_APP_API_URL}/public`

    return (
        <BadgeContext.Provider
            value={{
                user: user,
                jwtToken: jwtToken,
                setJwtToken: setJwtToken,

                badgeBucketBaked: badgeBucketBaked,
                badgeBucketOriginal: badgeBucketOriginal,
                apiOpenBadge: apiOpenBadge,
                setUser: setUser,

                userId: userId,
                setUserId: setUserId,
                userEmail: userEmail,
                setUserEmail: setUserEmail,
                userRole: userRole,
                setUserRole: setUserRole,
                badges: badges,
                badgeMetadata: badgeMetadata,
                setBadgeMetadata: setBadgeMetadata,
                userMetadata: userMetadata,
                setUserMetadata: setUserMetadata,
                assertionMetadata: assertionMetadata,
                setAssertionMetadata: setAssertionMetadata,
                setBadges: setBadges,
                users: users,
                setUsers: setUsers,
                assertions: assertions,
                setAssertions: setAssertions,
                userAssertions: userAssertions,
                setUserAssertions: setUserAssertions,
                wishlist: wishlist
            }}
        >
            {props.children}
        </BadgeContext.Provider>
    )
}

export default GlobalState
