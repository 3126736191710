import {
	cardTitle,
	successColor,
	dangerColor,
	grayColor
} from 'assets/jss/material-dashboard-pro-react.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'

const createBadgeStyles = {
	...cardTitle,
	card: {
		margin: '15px'
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		'& small': {
			fontSize: '80%',
			fontWeight: '400'
		}
	},
	formControl: {
		// margin: theme.spacing(1),
		fullWidth: true
	},
	badgeImage: {
		textAlign: 'center',
		margin: 10,
		height: 200,
		width: 200
	},
	labelHorizontal: {
		// color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: '14px',
		lineHeight: '1.428571429',
		fontWeight: '400',
		paddingTop: '39px',
		marginRight: '0',
		'@media (min-width: 992px)': {
			float: 'right'
		}
	},
	cardCategory: {
		marginTop: '10px',
		color: grayColor[0] + ' !important',
		textAlign: 'center'
	},
	description: {
		color: grayColor[0]
	},
	updateProfileButton: {
		float: 'right'
	},
	...customCheckboxRadioSwitch,
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px'
	},
	staticFormGroup: {
		marginLeft: '0',
		marginRight: '0',
		paddingBottom: '10px',
		margin: '8px 0 0 0',
		position: 'relative',
		'&:before,&:after': {
			display: 'table',
			content: '" "'
		},
		'&:after': {
			clear: 'both'
		}
	},
	staticFormControl: {
		marginBottom: '0',
		paddingTop: '8px',
		paddingBottom: '8px',
		minHeight: '34px'
	},
	inputAdornment: {
		marginRight: '8px',
		position: 'relative'
	},
	inputAdornmentIconSuccess: {
		color: successColor[0] + '!important'
	},
	inputAdornmentIconError: {
		color: dangerColor[0] + '!important'
	}
}
export default createBadgeStyles
