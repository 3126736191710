import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const BadgesStyles = {
  certification: {
    backgroundColor: primaryColor[0]
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px"
    // marginBottom: "0px"
  },
  avatar: {
    margin: 10,
    backgroundColor: "#fff"
  }
};

export default BadgesStyles;
