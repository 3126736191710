import { badgerApi } from "./ApiConfig"

//############################################################
// API starts here
//############################################################

// export function getUsers() {
//   return users;
// }

export function getUsers(authorization) {
    return badgerApi
        .get("/users", {
            headers: {
                authorization: authorization
            }
        })
        .then(res => res.data)
        .catch(err => console.error(err))
}

export function getUser(authorization, id) {
    return badgerApi
        .get("/users/" + id, {
            headers: {
                authorization: authorization
            }
        })
        .then(res => res.data)
        .catch(err => console.error(err))
}

export function getUserAssertions(authorization, id) {
    return badgerApi
        .get("/users/" + id + "/assertions", {
            headers: {
                authorization: authorization
            }
        })
        .then(res => res.data)
        .catch(err => console.error(err))
}

export function getUserMetadata(authorization) {
    return badgerApi
        .get("/users/meta", {
            headers: {
                authorization: authorization
            }
        })
        .then(res => res.data)
        .catch(err => err.response.data)
}
