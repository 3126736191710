import React, { useState, useEffect, useContext } from "react"
import BadgeContext from "../../context/badge-context"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import { NavLink } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Warning from "@material-ui/icons/Warning"
import Public from "@material-ui/icons/Public"
import Slide from "@material-ui/core/Slide"

import CardFooter from "components/Card/CardFooter.jsx"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import CardActionArea from "@material-ui/core/CardActionArea"
import backpackStyle from "views/Backpack/BackpackStyle"
import badgeImage from "assets/img/badges/badge-placeholder.png"
import Typography from "@material-ui/core/Typography"
import { getUserAssertions } from "../../api/Users"

const Backpack = props => {
    const GC = useContext(BadgeContext)

    const [, setUpload] = useState(false)
    const { classes } = props

    const today = new Date()

    const addDefaultSrc = e => {
        e.target.src = badgeImage
    }
    // Run only once
    useEffect(() => {
        getUserAssertions(GC.jwtToken, GC.userId).then(res => {
            if (!res) {
                res = []
            }
            GC.setUserAssertions(res)
        })
    }, [])

    const AssertionsGrid = () => {
        return (
            <GridContainer>
                {GC.userAssertions.map((assertion, key) => {
                    let expired = new Date(assertion.expires).getTime() < today.getTime()
                    return (
                        <React.Fragment key={key}>
                            <GridItem xs={6} sm={4} md={4} lg={3}>
                                <Card raised key className={expired ? classes.expiredBadge : null}>
                                    <CardActionArea className={classes.badgeActionArea}>
                                        <NavLink
                                            to={{
                                                pathname: "/admin/assertion/" + assertion.pk.split(":")[1],
                                                state: {
                                                    assertion: assertion
                                                }
                                            }}
                                        >
                                            <CardHeader icon>
                                                <Grid container justify="center" alignItems="center">
                                                    <img
                                                        src={`${GC.badgeBucketOriginal}/${
                                                            assertion.d2.split(":")[1]
                                                        }.png`}
                                                        alt="Badge"
                                                        onError={addDefaultSrc}
                                                        style={{
                                                            maxWidth: "70%",
                                                            paddingTop: "10px"
                                                        }}
                                                    />
                                                </Grid>
                                            </CardHeader>
                                            <CardBody>
                                                <Grid container justify="center" alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="button"
                                                            gutterBottom
                                                            className={classes.badgeTitle}
                                                        >
                                                            {assertion.badge.toUpperCase()}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {""}
                                                    </Grid>
                                                </Grid>
                                            </CardBody>
                                        </NavLink>
                                    </CardActionArea>
                                </Card>
                            </GridItem>
                        </React.Fragment>
                    )
                })}
            </GridContainer>
        )
    }

    const BadgesCard = () => {
        return (
            <GridItem xs={12} sm={12} md={12} lg={9}>
                <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>My Badges</h4>
                    </CardHeader>
                    <CardBody>
                        <AssertionsGrid />
                    </CardBody>
                </Card>
            </GridItem>
        )
    }

    return (
        <GridContainer>
            {/* <StatisticsComponent /> */}
            {/* <Interactions /> */}
            <BadgesCard />
        </GridContainer>
    )
}

export default withStyles(backpackStyle)(Backpack)
