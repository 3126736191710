import { badgerApi } from './ApiConfig'

export function getAssertions(authorization) {
	return badgerApi
		.get('/assertions', {
			headers: {
				authorization: authorization
			}
		})
		.then(res => res.data)
		.catch(err => console.error(err))
}

export function getAssertion(authorization, id) {
	return badgerApi
		.get(`/assertions/${id}`, {
			headers: {
				authorization: authorization
			}
		})
		.then(res => res.data)
		.catch(err => console.error(err))
}

// Good working example
export function updateAssertion(authorization, id, payload) {
	return badgerApi
		.patch(`/assertions/${id}`, payload, {
			headers: {
				authorization: authorization
			}
		})
		.then(res => res.data)
		.catch(err => console.error(err))
}

export function deleteAssertion(authorization, id) {
	return badgerApi
		.delete(`/assertions/${id}`, {
			headers: {
				authorization: authorization
			}
		})
		.then(res => res.data)
		.catch(err => console.error(err))
}

export function getAssertionMetadata(authorization) {
	return badgerApi
		.get('/assertions/meta', {
			headers: {
				authorization: authorization
			}
		})
		.then(res => res.data)
		.catch(err => console.error(err))
}
