import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import Amplify from 'aws-amplify'
import GlobalState from './context/GlobalState'

Amplify.configure({
    Auth: {
        // identityPoolId: "us-east-1:3c8d8182-1caf-4a2c-9445-9203013e023d",
        // region: 'us-east-1',
        // userPoolId: 'us-east-1_V50DADkcN',
        userPoolWebClientId: '69utsbm8qr2tad0g61v65mak6r',
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
})

console.log(process.env)

const app = (
    <GlobalState>
        <App />
    </GlobalState>
)

ReactDOM.render(app, document.getElementById('root'))
