import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import withStyles from "@material-ui/core/styles/withStyles"
import DownloadIcon from "@material-ui/icons/CloudDownload"
import ViewIcon from "@material-ui/icons/Pageview"
import PersonIcon from "@material-ui/icons/Person"
import RefreshIcon from "@material-ui/icons/Refresh"
import { getUsers } from "api/Users"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import matchSorter from "match-sorter"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { CSVLink } from "react-csv"
import { NavLink } from "react-router-dom"
import ReactTable from "react-table"
import BadgeContext from "../../context/badge-context"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px"
        // marginBottom: "0px"
    }
}

const Users = props => {
    const globalContext = useContext(BadgeContext)
    let colors = ["#f44336", "#e91e63", "#9c27b0", "#ea80fc", "#ff80ab", "#2196f3", "#3f51b5"]

    useEffect(() => {
        fetchUsers()
    }, [])

    function fetchUsers() {
        if (globalContext.jwtToken) {
            getUsers(globalContext.jwtToken).then(res => {
                globalContext.setUsers(res)
            })
        }
    }

    const { classes } = props
    const columns = [
        {
            accessor: "image",
            width: 60,
            filterable: false,
            sortable: false,
            Cell: row => (
                <Grid container justify="center" alignItems="center">
                    <Avatar
                        style={{
                            backgroundColor: colors[Math.floor(Math.random() * colors.length)]
                        }}
                    >
                        <PersonIcon />
                    </Avatar>
                </Grid>
            )
        },
        {
            Header: "Email",
            accessor: "email",
            // maxWidth: 200,
            //sortable: false,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => {
                return (
                    <TextField
                        id="searching email"
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        placeholder="Search by user..."
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            }
        },
        {
            Header: "Count",
            accessor: "issueCount",
            minWidth: 30,
            filterable: false,
            sortable: true,
            Cell: row => {
                if (row.original.issueCount) {
                    return row.original.issueCount
                } else {
                    return 0
                }
            }
        },
        {
            Header: "Modified",
            accessor: "modified",
            minWidth: 30,
            filterable: false,
            sortable: true,
            Cell: row => {
                var d = new Date(row.original.lastUpdated)
                if (d instanceof Date && !isNaN(d)) {
                    return d.toLocaleDateString()
                } else {
                    return null
                }
            }
        },
        {
            Header: "Created",
            accessor: "created",
            minWidth: 30,
            filterable: false,
            sortable: true,
            Cell: row => {
                var d = new Date(row.original.created)
                if (d instanceof Date && !isNaN(d)) {
                    return d.toLocaleDateString()
                } else {
                    return null
                }
            }
        },
        {
            Header: "Logged In",
            accessor: "lastLoggedIn",
            minWidth: 30,
            filterable: false,
            sortable: true,
            Cell: row => {
                var d = new Date(row.original.lastLoggedIn)
                if (d instanceof Date && !isNaN(d)) {
                    return d.toLocaleDateString()
                } else {
                    return null
                }
            }
        },
        {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            minWidth: 40,
            Cell: row => (
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <NavLink
                            to={{
                                pathname: "/admin/user/" + row.original.pk.split(":")[1]
                            }}
                        >
                            <IconButton color="default" className={classes.button} size="small" edge="end">
                                <ViewIcon />
                            </IconButton>
                        </NavLink>
                    </Grid>
                </Grid>
            )
        }
    ]

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader
                        action={
                            <React.Fragment>
                                <CSVLink
                                    data={globalContext.users}
                                    filename={moment().format("YYYY-MM-DDTHH-mm-ss") + "-badger-export-users.csv"}
                                >
                                    <IconButton aria-label="Download">
                                        <DownloadIcon />
                                    </IconButton>
                                </CSVLink>

                                <IconButton aria-label="Refresh" onClick={fetchUsers}>
                                    <RefreshIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    >
                        {/* <h4>Assertions</h4> */}
                    </CardHeader>
                    <CardContent>
                        <ReactTable
                            data={globalContext.users}
                            filterable
                            sortable
                            defaultSorted={[
                                {
                                    // the sorting model for the table
                                    id: "issueCount",
                                    desc: true
                                }
                            ]}
                            columns={columns}
                            defaultPageSize={50}
                            className="-striped -highlight"
                        />
                    </CardContent>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

Users.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Users)
