// core components
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AddAlert from "@material-ui/icons/AddAlert"
import DownloadIcon from "@material-ui/icons/CloudDownload"
import EditIcon from "@material-ui/icons/Edit"
import ViewIcon from "@material-ui/icons/Pageview"
import RefreshIcon from "@material-ui/icons/Refresh"
// import { imageBucket } from "../../../public/config";
import { getBadges } from "api/Badges"
import badgeImage from "assets/img/badges/badge-placeholder.png"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Snackbar from "components/Snackbar/Snackbar.jsx"
import matchSorter from "match-sorter"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { NavLink } from "react-router-dom"
// import UserApi from "../../Api/users";
// react component for creating dynamic tables
import ReactTable from "react-table"
import BadgesStyles from "views/Badges/BadgesStyles"
import BadgeContext from "../../context/badge-context"
//import { TextField } from '@material-ui/core/colors/green.js'
//import "react-table/react-table.css";
import color from "@material-ui/core/colors/yellow"
import { colors } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import { green } from "@material-ui/core/colors"
//import TextField from "@material-ui/core/TextField";
const Badges = props => {
    const GC = useContext(BadgeContext)

    const [trNotification, setTrNotification] = useState(false)
    const [] = useState(0)
    const { classes } = props
    // console.log("badges", userState)

    useEffect(() => {
        fetchBadges()
    }, [])

    function fetchBadges() {
        if (GC.jwtToken) {
            getBadges(GC.jwtToken).then(res => {
                GC.setBadges(res)
            })
        }
    }

    const addDefaultSrc = e => {
        e.target.src = badgeImage
    }

    const columns = [
        {
            accessor: "image",
            maxWidth: 100,
            filterable: false,
            sortable: false,

            Cell: row => (
                <Grid container justify="center" alignItems="center" backgroundColor="red">
                    <img
                        src={`${GC.badgeBucketOriginal}/${row.original.image}`}
                        onError={addDefaultSrc}
                        alt="Badge"
                        style={{ maxWidth: "100%" }}
                    />
                </Grid>
            )
        },
        {
            Header: "Name",
            accessor: "name",
            maxWidth: 200,
            sortable: false,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => {
                return (
                    <TextField
                        id="search-name"
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        placeholder="Search by name..."
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            }
        },
        {
            Header: "Description",
            accessor: "description",
            sortable: false,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["description"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => {
                return (
                    <TextField
                        id="search-desc"
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        placeholder="Search by description..."
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            }
        },
        {
            Header: "Last Issued",
            accessor: "lastIssued",
            minWidth: 30,
            filterable: false,
            sortable: true,
            style: { textAlign: "center" },
            Cell: row => {
                var d = new Date(row.original.lastIssued)
                if (d instanceof Date && !isNaN(d)) {
                    return d.toLocaleDateString()
                } else {
                    return null
                }
            }
        },
        {
            Header: "Count",
            accessor: "issueCount",
            minWidth: 20,
            filterable: false,
            style: { textAlign: "center" },
            Cell: row => {
                if (row.original.issueCount) {
                    return row.original.issueCount
                } else {
                    return 0
                }
            }
        },
        {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            minWidth: 40,
            Cell: row => {
                let isAdmin, isOwner, isReviewer

                isAdmin = GC.userRole === "Admin"

                if ("owners" in row.original) {
                    isOwner = row.original.owners.includes(GC.userEmail)
                }

                if ("reviewers" in row.original) {
                    isReviewer = row.original.reviewers.includes(GC.userEmail)
                }

                return (
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <NavLink
                                to={{
                                    pathname: "/admin/badge/" + row.original.pk.split(":")[1]
                                }}
                            >
                                <IconButton
                                    color="default"
                                    className={classes.button}
                                    size="small"
                                    edge="end"
                                    aria-label="add an alarm"
                                >
                                    <ViewIcon />
                                </IconButton>
                            </NavLink>
                        </Grid>
                        {/* Only show if admin */}
                        {isAdmin || isOwner || isReviewer ? (
                            <Grid item>
                                <NavLink
                                    to={{
                                        pathname: "/admin/edit-badge/" + row.original.pk.split(":")[1]
                                    }}
                                >
                                    <IconButton
                                        color="default"
                                        className={classes.button}
                                        aria-label="add an alarm"
                                        size="small"
                                        edge="end"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </NavLink>
                            </Grid>
                        ) : null}
                    </Grid>
                )
            }
        }
    ]

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader
                        action={
                            <React.Fragment>
                                {GC.userRole === "Admin" ? (
                                    <CSVLink
                                        data={GC.badges}
                                        filename={moment().format("YYYY-MM-DDTHH-mm-ss") + "-badger-export-badges.csv"}
                                    >
                                        <IconButton aria-label="Download">
                                            <DownloadIcon />
                                        </IconButton>
                                    </CSVLink>
                                ) : null}
                                <IconButton aria-label="Refresh" onClick={fetchBadges}>
                                    <RefreshIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                    <ReactTable
                        data={GC.badges}
                        filterable
                        defaultSorted={[
                            {
                                // the sorting model for the table
                                id: "issueCount",
                                desc: true
                            }
                        ]}
                        defaultSortDesc={true}
                        resizable={false}
                        columns={columns}
                        defaultPageSize={20}
                        className="-striped -highlight"
                    />
                    <CardContent />
                </Card>
            </GridItem>
            <Snackbar
                place="tr"
                color="success"
                icon={AddAlert}
                message="Added badge to your wishlist"
                open={trNotification}
                closeNotification={() => setTrNotification(false)}
                close
            />
        </GridContainer>
    )
}

Badges.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(BadgesStyles)(Badges)
