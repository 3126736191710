import React, { useContext, useEffect, useState } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import CardBody from "components/Card/CardBody.jsx";
import cardBodyStyle from "assets/jss/material-dashboard-pro-react/components/cardBodyStyle.jsx";

const About = props => {

	return (
		<CardBody >
			<h2>Badger</h2>
			<h3><i>Skills gamification engine for driving digital transformation of human resources</i></h3>
			<h4>
				Humans are natural collectors, and badges provide a straightforward way for them to gather and clearly present their characteristics, skills, interests, and other information of interest to their colleagues and employers.<br></br>
				<br></br>
				Badger is designed to commodify and gamify skills acquisition and transformation, as a key component of DXC's digital transformation program.
            <br></br>
				<br></br>
				Badges allow motivated employees to demonstrate their "digitalisation", and for the identification of those who require extra help.</h4>
		</CardBody>
	)

};

export default withStyles(cardBodyStyle)(About);